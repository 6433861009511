import { getType, isFn, isNullOrEmpty, isNullOrWhitespace } from '@/utils/obj-utils';
import { CASE_TYPE } from '@/utils/constants';

export const formatString = (str, data, pattern = null, forceReplace = false) => {
  if (isNullOrEmpty(str)) return null;
  const dataKeys = Object.keys(data ?? {});
  return str.replace(pattern ?? /##.*?##/gi, (match) => {
    const key = match.substring(2, match.length - 2);
    for (let dataKey of dataKeys) {
      if (equalString(dataKey, key)) return data[dataKey];
    }
    return forceReplace ? '' : match;
  });
};
export const equalString = (str1, str2, ignoreCase = true, ignoreWhitespace = true) => {
  let a = str1,
    b = str2;
  if (isNullOrEmpty(a) || isNullOrEmpty(b)) {
    return a === b;
  }
  const aType = getType(a),
    bType = getType(b);
  if (aType !== '[object String]' && isFn(a.toString)) a = a.toString();
  if (bType !== '[object String]' && isFn(b.toString)) b = b.toString();
  if (aType === '[object Number]') a = '' + a;
  if (bType === '[object Number]') b = '' + b;
  if (isNullOrEmpty(a) || isNullOrEmpty(b)) {
    return a === b;
  }
  if (ignoreCase) {
    a = isFn(a?.toLowerCase) ? a.toLowerCase() : a;
    b = isFn(b?.toLowerCase) ? b.toLowerCase() : b;
  }
  if (ignoreWhitespace) {
    a = a.replace(/\s*/gi, '');
    b = b.replace(/\s*/gi, '');
  }
  return a === b;
};
/**
 * @param {Number} len
 * @param {Number} type [1=uppercase|2=lowercase|3=mixed]
 * @returns {String}
 */
export const randomString = (len, type) => {
  const str = '0123456789abcdefghijklmnopqrstuvwxyz';
  const result = '';
  for (let i = i; i < len; i++) {
    let char = str[Math.floor(Math.random() * str.length)];
    char = type === 1 ? char.toUpperCase() : type === 2 ? char.toLowerCase() : Math.random() >= 0.5 ? char.toUpperCase() : char.toLowerCase();
    result.push(char);
  }
  return result;
};
export const toCapitalize = (str) => {
  if (isNullOrWhitespace(str)) return str;
  return str
    .split(' ')
    .map((word) => toFistLetterUpper(word))
    .join(' ');
};
export const toFistLetterUpper = (str) => {
  if (isNullOrWhitespace(str)) return str;
  if (str.length === 1) {
    return str.toUpperCase();
  }
  return str[0].toUpperCase() + str.substring(1, str.length).toLowerCase();
};
/**
 * @param {Array<String>} stringArray
 * @param {string} separator
 * @param {string} formatter
 * @returns {String}
 */
export const concatString = (stringArray, separator = ', ', formatter) => {
  let result = '';
  for (let str of stringArray) {
    if (isNullOrWhitespace(str)) continue;
    if (formatter instanceof Function) {
      str = formatter(str);
    }
    if (result !== '') {
      result += separator;
    }
    result += str;
  }
  return result;
};
export const ifEmpty = (...strArray) => {
  return ifEmptyField(strArray);
};
export const ifEmptyField = (ObjArray, valueFn) => {
  for (let obj of ObjArray) {
    const objVal = valueFn ? valueFn(obj) : obj;
    if (isNullOrEmpty(objVal)) continue;
    return obj;
  }
};
export const toPureText = (text) => {
  if (isNullOrEmpty(text)) return text;
  return text.replace(/<\/?.*?>/gi, '');
};
export const byteLen = (text) => {
  if (isNullOrEmpty(text)) return 0;
  let result = 0;
  for (let i = 0, len = text.length; i < len; i++) {
    if (text.charCodeAt(i) > 255) result += 4;
    else result += 2;
  }
  return result;
};
export const byteCut = (text, length, postFix = '...') => {
  if (isNullOrEmpty(text)) return text;
  const byteLength = byteLen(text);
  if (length >= byteLength) return text;
  let result = '';
  for (let i = 0, j = 0, len = text.length; i < len; i++) {
    if (text.charCodeAt(i) > 255) j += 4;
    else j += 2;
    if (j >= length) break;
    result += text.charAt(i);
  }
  result.replace(/\s$/g, '');
  result += postFix;
  return result;
};

import Md5 from 'crypto-js/md5';

const _k = (key, expireMinutes = 60 * 24 * 2) => {
  return {
    keyData: Md5(key).toString(),
    expireMinutes: expireMinutes
  };
};
export const S_LANG = _k('S_LANG', null);
export const S_SITE_ERRORS = _k('S_SITE_ERRORS', 15);
export const S_LOGIN_INFO = _k('S_LOGIN_INFO', null);
export const S_USER_INFO = _k('S_USER_INFO', null);
export const S_ORDER_INFO = _k('S_ORDER_INFO', null);
export const S_SHOP_ORDER_INFO = _k('S_SHOP_ORDER_INFO', null);
export const S_RESERVATION_DETAIL = _k('S_RESERVATION_DETAIL', null);
export const S_COUNTRIES = _k('S_COUNTRIES', 5);
export const S_DETAIL_LINK = _k('S_DETAIL_LINK', null);
export const S_SHIPPING_COUNTRIES = _k('S_SHIPPING_COUNTRIES', 5);
export const S_WEBSHOP_SHIPPING_COUNTRIES = _k('S_WEBSHOP_SHIPPING_COUNTRIES', 5);
export const S_REGISTRABLE_COUNTRIES = _k('S_REGISTRABLE_COUNTRIES', 5);
export const S_I18N = _k('S_I18N', 15);
export const S_LOGIN_URL = _k('S_LOGIN_URL', null);
export const S_MARKET_DETECTOR_CLOSED = _k('S_MARKET_DETECTOR_CLOSED', 60 * 24 * 2);
export const S_ORDER_STATUSES = _k('S_ORDER_STATUSES', 5);
export const S_WHEELS_ORDER_STATUSES = _k('S_WHEELS_ORDER_STATUSES', 5);
export const S_SERVICE_PLAN_ORDER_STATUSES = _k('S_SERVICE_PLAN_ORDER_STATUSES', 5);
export const S_ORDER_VEHICLE_STATUSES = _k('S_ORDER_VEHICLE_STATUSES', 5);
export const S_CAR_MODELS = _k('S_CAR_MODELS', 1);
export const S_CHAT_CONFIG = _k('S_CHAT_CONFIG', 5);
export const S_ORDER_LIST = _k('S_ORDER_LIST', 5);
export const S_MALL_ORDER_LIST = _k('S_MALL_ORDER_LIST', 5);
export const S_PARTS_ORDER_LIST = _k('S_PARTS_ORDER_LIST', 5);
export const S_SERVICE_ORDER_LIST = _k('S_SERVICE_ORDER_LIST', 5);
export const S_HIDE_MALL_ORDER = _k('S_HIDE_MALL_ORDER', 5);
export const S_HIDE_VEHICLE_ORDER = _k('S_HIDE_VEHICLE_ORDER', 5);
export const S_HIDE_WHEELS_ORDER = _k('S_HIDE_WHEELS_ORDER', 5);
export const S_HIDE_SERVICE_ORDER = _k('S_HIDE_SERVICE_ORDER', 5);
export const S_ORDER_RULES = _k('S_ORDER_RULES', 5);
export const S_PROVINCE_LIST = _k('S_PROVINCE_LIST', 5);
export const S_SANTANDER_LOAN = _k('S_SANTANDER_LOAN', 1);
export const S_CODE_WAVER_FINANCE = _k('S_CODE_WAVER_FINANCE', 2);
export const S_CA_AUTO_BANK_CONFIG = _k('S_CA_AUTO_BANK_CONFIG', 2);
export const S_FINANCE_STATUSES = _k('S_FINANCE_STATUSES', 2);
export const S_FINANCE_PRODUCT_MAP = _k('S_FINANCE_PRODUCT_MAP', 30);
export const S_ALL_CAR_PRICES = _k('S_ALL_CAR_PRICES', 5);
export const S_AFTER_SALES_INSTALL = _k('S_AFTER_SALES_INSTALL', 5);
export const S_GLOBAL_CONFIG = _k('S_GLOBAL_CONFIG', 60);
export const S_BOUND_INFO = _k('S_BOUND_INFO', 2);
export const S_LANGUAGES = _k('S_LANGUAGES', 1);
export const S_STORE_TYPES = _k('S_STORE_TYPES', 5);
export const S_GEO_LOCATION = _k('S_GEO_LOCATION', 30);
export const S_CATEGORIES = _k('S_CATEGORIES', 1);
export const S_CART_INFO = _k('S_CART_INFO', null);
export const S_SERVER_CART_INFO = _k('S_SERVER_CART_INFO', 0.5);
export const S_STORE_LINKS = _k('S_STORE_LINKS', 5);
export const S_DELIVERY_TIME = _k('S_DELIVERY_TIME', 5);
export const S_MHP_PRODUCTS = _k('S_MHP_PRODUCTS', 5);
export const S_VEHICLE_CONFIG_LIST = _k('S_VEHICLE_CONFIG_LIST', 0.01);
export const S_DATE_PICKER_CONFIG = _k('S_DATE_PICKER_CONFIG', 5);
export const S_LOCATION_TYPES = _k('S_DATE_PICKER_CONFIG', 5);

import { hasProperty, isArray, isNullOrEmpty } from '@/utils/obj-utils';
import { merge } from 'lodash';
import utils from '@/utils/index';

export const buildInheritFields = (fields) => {
  if (isNullOrEmpty(fields)) return null;
  let { inheritFrom, ...rest } = fields;
  if (isNullOrEmpty(inheritFrom?.fields)) return rest;
  const keys = Object.keys(rest);
  const result = utils.obj.deepCopy(inheritFrom?.fields);
  for (let key of keys) {
    if (utils.obj.hasProperty(result, key)) {
      const putNew = (isArray(result[key]) && rest[key].length > 0) || !isNullOrEmpty(rest[key]?.value);
      if (putNew) result[key] = rest[key];
    } else {
      result[key] = rest[key];
    }
  }
  return result;
};
export const buildFormItems = (formItems, id = null) => {
  return {
    id: id,
    fields: {
      items: formItems.map((x) => {
        return {
          fields: utils.oneForm.buildInheritFields(x.fields)
        };
      })
    }
  };
};

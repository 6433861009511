import { getSiteError } from '@/services/siteService';
import { injectScript } from '@/utils/dom-utils';
import { getGlobalConfigs, isLocalServer } from '@/utils/site-utils';
import { isNullOrEmpty, isNullOrWhitespace } from '@/utils/obj-utils';
import { equalString } from '@/utils/string-utils';
import { getCurrentAlpha2Code } from './site-utils';
import { merge } from 'lodash';

export const loadStripe = async (page, toast) => {
  const [stripeSrc, stripeApiKey, stripeLocales] = getGlobalConfigs(page, 'stripeSrc', 'stripeApiKey', 'stripeLocales');
  if (!stripeSrc?.value) {
    const msg = await getSiteError('stripe_not_prepared');
    await toast.showError(msg);
    return;
  }
  let locale = 'en';
  if (!isNullOrWhitespace(stripeLocales?.value)) {
    const stripeLocaleArray = stripeLocales?.value.split(';');
    if (page.itemLanguage.indexOf('-') > 0) {
      const [language] = page.itemLanguage.split('-');
      if (stripeLocaleArray.some((x) => equalString(x, page.itemLanguage))) {
        locale = page.itemLanguage;
      } else if (stripeLocaleArray.some((x) => equalString(x, language))) {
        locale = language;
      }
    }
  }
  await injectScript(stripeSrc?.value, () => !!global.Stripe);
  return {
    Stripe: global.Stripe,
    initSdk(payInfo) {
      const options = { locale: locale ?? 'auto' };
      if (!isNullOrEmpty(payInfo.stripeAccount)) {
        merge(options, {
          stripeAccount: payInfo.stripeAccount
        });
      }
      return global.Stripe(payInfo?.publicKey ?? stripeApiKey.value, options);
    },
    initWidget(stripe, container, payInfo, onPaymentChange) {
      let paymentChangeCallback;
      const appearance = {
        theme: 'stripe',
        variables: {
          // colorPrimary: '#0570de',
          // colorBackground: '#ffffff',
          colorText: '#000',
          // colorDanger: '#df1b41',
          // spacingUnit: '2px',
          borderRadius: '0px'
        }
        // theme: 'none',
        // rules: {}
      };
      const options = { appearance, clientSecret: payInfo.prePay };
      if (isLocalServer()) {
        options.locale = locale;
      }
      let elements = stripe.elements(options);
      const country = getCurrentAlpha2Code();
      const paymentOptions = {
        defaultValues: {
          // name: '',
          // phone: '888-888-8888',
          address: {
            // postal_code: '10001',
            country
          }
        }
      };
      if (!isNullOrEmpty(payInfo.email)) {
        merge(paymentOptions, {
          defaultValues: {
            billingDetails: {
              email: payInfo.email
            }
          }
        });
      }
      const paymentElement = elements.create('payment', paymentOptions);
      paymentElement.mount(container);
      const confirmPayment = async (returnUrl) => {
        // console.log('returnUrl', locale, returnUrl);
        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: returnUrl
          }
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === 'card_error' || error.type === 'validation_error') {
          await toast.showError(error.message);
        } else {
          await toast.showError('An unexpected error occurred');
        }
        return error;
      };
      paymentElement.on('change', (e) => {
        onPaymentChange && onPaymentChange(e);
      });
      return confirmPayment;
    }
  };
};

export default `
<svg width="132" height="131" viewBox="0 0 132 131" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_b_908_8974)">
<circle cx="66.2383" cy="65.4999" r="65.5" fill="black" fill-opacity="0.2"/>
</g>
<g filter="url(#filter1_d_908_8974)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M46.2422 33.8771L44.7383 32.9999V34.7409V98.7409V100.51L46.2539 99.5977L100.254 67.0977L101.7 66.2275L100.242 65.3771L99.6702 65.0435L97.7379 66.2922L97.7256 66.2851L46.7383 96.9719V36.482L92.8249 63.3658L94.8392 62.2254L46.2422 33.8771Z" fill="white"/>
</g>
<defs>
<filter id="filter0_b_908_8974" x="-14.2617" y="-15.0001" width="161" height="161" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_908_8974"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_908_8974" result="shape"/>
</filter>
<filter id="filter1_d_908_8974" x="24.7383" y="12.9999" width="96.9609" height="107.51" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="10"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.8 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_908_8974"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_908_8974" result="shape"/>
</filter>
</defs>
</svg>
`;

import { round } from '@/utils/math-utils';

/**
 * @param {GeoPoint} p1
 * @param {GeoPoint} p2
 * @return {number} distance
 */
export const getGeoDistance = (p1, p2) => {
  console.log('p1, p2', p1, p2);
  const a = p1.latitude - p2.latitude;
  const b = p1.longitude - p2.longitude;
  const lat1 = p1.latitude,
    lat2 = p2.latitude;
  const s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(b / 2), 2)));
  return round(6378137 * s);
};

export default `
<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Ellipse 9" filter="url(#filter0_b_989_4993)">
<circle cx="19" cy="19" r="19" fill="url(#paint0_radial_989_4993)" fill-opacity="0.5"/>
</g>
<defs>
<filter id="filter0_b_989_4993" x="-12" y="-12" width="62" height="62" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="6"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_989_4993"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_989_4993" result="shape"/>
</filter>
<radialGradient id="paint0_radial_989_4993" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.9304 53.2) rotate(-89.8834) scale(34.2001 22.9272)">
<stop stop-color="#4F4F4F" stop-opacity="0.8"/>
<stop offset="1"/>
</radialGradient>
</defs>
</svg>
`;

export const APP_CONST = {
  lang: 'en',
  market: {
    phrase: 'EU',
    code: 'EU',
    mhpCode: 'MHP_EU_MARKET'
  },
  carPriceList: null
};
export const BREAKPOINTS = {
  mobile: 768,
  tablet: 992,
  desktop: 1200,
  desktopLarge: 1600
};
export const REQUEST_TYPES = {
  web: 'web',
  cms: 'cms',
  bff: 'bff',
  crm: 'crm',
  mhp: 'mhp',
  lts: 'lts',
  gm: 'gm',
  cw: 'cw'
};
export const CAPTCHA_TYPE = {
  phone: 1,
  email: 2
};
export const CAPTCHA_SCENE = {
  login: 101,
  secondVerification: 102,
  bindThirdPartyAccount: 103,
  register: 201,
  registerLogin: 202,
  forgetPassword: 301,
  changePassword: 302,
  cancel: 303,
  bindPhone: 309,
  bindEmail: 310
};
export const MAP_NIGHT_STYLE = [
  { elementType: 'labels.text.stroke', stylers: [{ color: '#D3D4D5' }] },
  { elementType: 'labels.text.fill', stylers: [{ color: '#4F4F4F' }] },
  {
    featureType: 'administrative.locality',
    elementType: 'geometry',
    stylers: [{ color: '#D3D4D5' }]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#4F4F4F' }]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#ECEDED' }]
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [{ color: '#D3D4D5' }]
  },
  {
    featureType: 'landscape',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'poi',
    stylers: [{ visibility: 'off' }]
  }
];
export const MAP_BLACK_STYLE = [
  {
    elementType: 'geometry',
    stylers: [{ color: '#212121' }]
  },
  {
    elementType: 'labels.icon',
    stylers: [{ visibility: 'on' }]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ color: '#4F4F4F' }]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#2d2d2d' }]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [{ color: '#757575' }]
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9e9e9e' }]
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: [{ visibility: 'on' }]
  },
  {
    featureType: 'administrative.locality',
    elementType: 'geometry',
    stylers: [{ color: '#2d2d2d' }]
  },
  {
    featureType: 'administrative.neighborhood',
    stylers: [{ visibility: 'on' }]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text',
    stylers: [{ visibility: 'on' }]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#757575' }]
  },
  {
    featureType: 'poi.business',
    stylers: [{ visibility: 'on' }]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#181818' }]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#616161' }]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#1b1b1b' }]
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [{ color: '#333333' }]
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [{ visibility: 'on' }]
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'on' }]
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#8a8a8a' }]
  },
  {
    featureType: 'road.local',
    stylers: [{ visibility: 'on' }]
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#616161' }]
  },
  {
    featureType: 'transit',
    stylers: [{ visibility: 'on' }]
  },
  {
    featureType: 'transit',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#757575' }]
  },
  {
    featureType: 'transit.station.airport',
    elementType: 'geometry',
    stylers: [{ visibility: 'on' }]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#171717' }]
  },
  {
    featureType: 'water',
    elementType: 'labels.text',
    stylers: [{ visibility: 'on' }]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#3d3d3d' }]
  }
];
export const sitecoreProps = {
  id: null,
  name: null,
  url: null,
  displayName: null
};
export const sitecoreComponentProps = {
  page: {
    type: Object
  },
  rendering: {
    type: Object
  },
  errorComponent: {
    type: Object
  },
  params: {
    type: Object
  },
  ...sitecoreProps
};
export const accountTypes = {
  mobile: 1,
  email: 2,
  userName: 3
};
export const loginTypes = {
  password: 1,
  mobileVerifyCode: 2,
  emailVerifyCode: 3,
  scan: 4,
  thirdParty: 5,
  mobileQuickLogin: 6,
  wechatLogin: 7,
  wechatScan: 8,
  mobileJustLogin: 12,
  emailJustLogin: 17
};
export const captchaTypes = {
  mobile: 1,
  email: 2
};
export const addressTypes = {
  individual: 1,
  company: 2
};
export const CUSTOMER_TYPES = {
  individual: 1,
  business: 2
};
export const BUY_CAR_TYPES = {
  individual: '100',
  business: '200'
};
export const ERROR_CODES = {
  CrossBorderError: 9000003,
  FormDefaultError: 9000004,
  AssetLoadError: 9000005,
  SantanderLoadError: 9000006,
  NoSimulationError: 9000007,
  GeoRefused: 9000008,
  storeNotExist: 9001001,
  storeNotAvailable: 9001002,
  WrongAccountOrPassword: 10000023
};
export const FILTER_NAMES = {
  'Lambda-car-filter-base-01': 'ELETRE',
  'Lambda-car-filter-base-02': 'ELETRE-S',
  'Lambda-car-filter-base-03': 'ELETRE-R'
};
export const FILTER_ENVIRONMENTS = {
  City_Street: 'City_Street',
  Mountain: 'Mountain',
  Iceland: 'Iceland',
  Studio: 'Studio_Lotus_1'
};
export const CAMERA_ID = 'BS_Config_Ext_Front_High_Front_34_measurements';

export const ORDER_STATUES = {
  New: '100',
  Deposit: '200',
  DepositLock: '600',
  FullPayment: '30',
  InReview: '300',
  Refunding: '301',
  Refunded: '302',
  ContractSigned: '800',
  Delivered: '900',
  Canceled: '400',
  Expired: '401',
  ExpiredTobeConfirm: '402',
  NotRefundCanceled: '403',
  Closed: '502'
};
export const FINANCE_STATUES = {
  ApplicationInitiated: 'AI',
  ManualUnderwriting: 'M',
  Approved: 'A',
  Rejected: 'R',
  Cancelled: 'C',
  ContractSigned: 'S',
  Disbursed: 'F'
};

export const NOTIFICATION_TYPES = {
  normal: 'normal',
  success: 'success',
  error: 'error',
  warn: 'warn',
  fail: 'fail',
  link: 'link'
};
export const REMOTE_MODULE_PREFIX = 'lrm';
export const FINANCE_DEMO_PRICES = {
  '40870010': 95990,
  '40870020': 120990,
  '40870030': 150990
};
export const GEO_TYPE_MAP = {
  zipCode: 'postalCode',
  cityOrZipCode: 'address'
};
export const DELIVERY_TYPE_CODE = {
  servicePlan: 51081001,
  delivery: 51081002,
  pickup: 51081003,
  storeInstallation: 51081004
};
export const WEBSHOP_PAMENT_TYPE_CODE = {
  directPayment: 51021001,
  cartPayment: 51021002
};
export const WEBSHOP_PAMENT_STATUS_CODE = {
  paying: 51031002,
  depositPaid: 510310021,
  inProduction: 510310024,
  balabceToBePaid: 510310022,
  paid: 510310023,
  delivering: 51031003,
  confirming: 51031004,
  finished: 51031005,
  closed: 51031006,
  waitService: 51031008,
  onService: 51031009,
  dispatched: 51031011
};
export const WEBSHOP_ORDER_TYPE = {
  life: 51121001,
  aftersalesInstall: 51121007
};
export const WEBSHOP_SALE_AFTER_ITEM_TYPE = {
  project: 1,
  mountings: 2,
  servicePlan: 6
};
export const TRADE_PLATFORM = {
  app: 10,
  miniProgram: 20,
  chargingStation: 30,
  website: 40,
  h5: 50,
  publicAccount: 60,
  lcms: 70
};
export const PAY_CHANNEL = {
  aliPay: 10,
  wechat: 20,
  other: 70
};
// export const CREATE_ACCOUNT_SOURCE = {
//   normal: 11,
//   leaseDriver: 1104
// };
export const STRIPE_PAYMENT_TYPE = {
  pos: 72,
  deposit: 70,
  finalPay: 71
};
export const PAYMENT_TYPE = {
  reservation: 10,
  deposit: 30,
  balancePayment: 120
};
export const WEEKDAY_ARRAY = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const GOODS_DISABLE_TYPE = {
  offShelf: 'offShelf',
  outOfStock: 'outOfStock',
  noPrice: 'noPrice'
};
export const STOCK_CAR_ORG = {
  1: 'RCC',
  2: 'Store',
  3: 'Dealer'
};
export const DEFAULT_IMG = 'https://lotus-cm.nttdatadm.com/-/media/Project/Portal-Site/eletre-new/lotus-centre-home-range-pc.ashx?h=2430&iar=0&w=4320&hash=E0D150818C21844EB98B3208605C68CE';

import { getType, isNull, isNullOrEmpty, isNullOrWhitespace, isString, padding } from '@/utils/obj-utils';
import { APP_CONST } from '@/utils/constants';

export const parseDateStr = (str) => {
  let year = null,
    month = null,
    day = null,
    hour = null,
    minute = null,
    second = null;
  let datePart = null,
    timePart = null;
  if (str.indexOf(' ') > 0) {
    [datePart, timePart] = str.split(' ');
  } else {
    datePart = str;
  }
  const matches = /\D/g.exec(datePart);
  if (!matches?.length) return null;
  const separator = matches[0];
  if (/^\d{2}\D\d{2}\D\d{4}$/.test(datePart)) {
    [day, month, year] = datePart.split(separator);
  }
  if (/^\d{4}\D\d{2}\D\d{2}$/.test(datePart)) {
    [year, month, day] = datePart.split(separator);
  }
  if (timePart) {
    [hour, minute, second] = timePart.split(':');
  } else {
    hour = 0;
    minute = 0;
    second = 0;
  }
  return [year, month, day, hour, minute, second];
};
export const parseDate = (str) => {
  const [year, month, day, hour, minute, second] = parseDateStr(str);
  return new Date(year, month - 1, day - 1, hour, minute, second);
};
/**
 * @callback formatDate
 * @property {object} date
 * @property {String} format
 * */
export const formatDate = (date, format = 'yyyy-MM-dd') => {
  if (isNullOrWhitespace(date)) return null;
  const type = getType(date);
  let dateObj = null;
  if (type === '[object Number]') {
    dateObj = new Date(date);
  }
  if (type === '[object Date]') {
    dateObj = date;
  }
  let year = null,
    month = null,
    day = null,
    hour = null,
    minute = null,
    second = null;
  if (type === '[object String]') {
    [year, month, day, hour, minute, second] = parseDateStr(date);
  } else if (isNull(dateObj) || getType(dateObj) !== '[object Date]' || dateObj?.toString() === 'Invalid Date') {
    return null;
  } else {
    year = dateObj.getFullYear();
    month = dateObj.getMonth() + 1;
    day = dateObj.getDate();
    hour = dateObj.getHours();
    minute = dateObj.getMinutes();
    second = dateObj.getSeconds();
  }
  const o = {
    yyyy: year,
    MM: padding(month, 2),
    dd: padding(day, 2),
    HH: padding(hour, 2),
    mm: padding(minute, 2),
    ss: padding(second, 2)
  };
  return format.replace(/yyyy|MM|dd|HH|mm|ss/g, (match) => {
    return o[match];
  });
};
export const formatOrderDate = (dateStr, format = 'yyyy-MM-dd') => {
  if (isNullOrEmpty(dateStr) || dateStr.length < 16) return null;
  const day = Number(dateStr.substring(0, 2)),
    month = Number(dateStr.substring(3, 5)),
    year = Number(dateStr.substring(6, 10)),
    hour = Number(dateStr.substring(11, 13)),
    minutes = Number(dateStr.substring(14, 16));
  return formatDate(new Date(year, month - 1, day, hour, minutes), format);
};
export const getLastDayOfCurrentMonth = (format = 'dd/MM/yyyy') => {
  const date = new Date();
  date.setMonth(date.getMonth() + 1, 0);
  if (format === null) return date;
  return formatDate(date, format);
};
export const sysFormatDate = (date, options) => {
  const { lang } = APP_CONST;
  let dateObj = date;
  if (isString(dateObj)) dateObj = new Date(date);
  if (dateObj?.toString() === 'Invalid Date') return null;
  const formatter = new Intl.DateTimeFormat(lang, options);
  return formatter.format(dateObj);
};

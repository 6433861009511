import * as querystring from 'querystring';
import { canUseDOM } from '@/utils/dom-utils';
import { getType, isNullOrWhitespace } from '@/utils/obj-utils';
import { equalString } from '@/utils/string-utils';
export const CookieFormat = {
  string: 'string',
  query: 'query',
  json: 'json'
};
export const getCookie = (name, format = CookieFormat.string) => {
  let [result] = getCookies({ name, format });
  return result?.value;
};
export const getJsonCookie = (name) => {
  return getCookie(name, CookieFormat.json);
};
export const getQueryCookie = (name) => {
  return getCookie(name, CookieFormat.query);
};
export const getCookies = (...cookiePayloads) => {
  const cookieNames = cookiePayloads.map((x) => x.name);
  const allCookies = getAllCookies(cookieNames);
  if (allCookies.length === 0) {
    return Array.from({ length: cookiePayloads.length }, () => null);
  }
  const result = [];
  for (let cp of cookiePayloads) {
    const ck = allCookies.find((x) => equalString(x.name, cp.name));
    const item = {
      ...ck
    };
    if (!isNullOrWhitespace(ck?.value)) {
      if (cp.format === CookieFormat.json) {
        try {
          item.value = JSON.parse(ck.value);
        } catch (ex) {
          console.log('ex', ex);
        }
      } else if (cp.format === CookieFormat.query) {
        try {
          item.value = querystring.parse(ck.value);
        } catch (ex) {
          console.log('ex', ex);
        }
      }
    }
    result.push(item);
  }
  return result;
};
export const getAllCookies = () => {
  if (!canUseDOM() || !document.cookie) return [];
  const ckStrings = document.cookie.split('; ');
  const result = [];
  for (let ckStr of ckStrings) {
    const items = ckStr.split('=');
    if (items.length > 0) {
      result.push({
        name: items[0],
        value: items.slice(1).join('=')
      });
    }
  }
  return result;
};
export const setCookie = (name, value, expireDays, cookieFormat = CookieFormat.string) => {
  let cookieValue = value;
  const expiration = new Date();
  const type = getType(value);
  expiration.setDate(expiration.getDate() + expireDays);
  if (type === '[Object object]') {
    if (cookieFormat === CookieFormat.json) {
      cookieValue = encodeURIComponent(JSON.stringify(value));
    } else if (cookieFormat === CookieFormat.query) {
      cookieValue = querystring.stringify(value);
    }
  } else {
    cookieValue = encodeURIComponent(value);
  }
  if (cookieFormat === CookieFormat.json) {
    cookieValue = JSON.stringify(cookieValue);
  }
  document.cookie = `${name}=${cookieValue};expires=${expiration.toUTCString()};path=/;domain=.lotuscars.com.cn`;
};

export default `
<svg width="86" height="85" viewBox="0 0 86 85" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_908_8988)">
<path d="M38.7383 33.9999V51.9999" stroke="white" stroke-width="2"/>
<path d="M47.7383 33.9999V51.9999" stroke="white" stroke-width="2"/>
</g>
<defs>
<filter id="filter0_d_908_8988" x="0.738281" y="-0.00012207" width="85" height="85" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="15"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.8 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_908_8988"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_908_8988" result="shape"/>
</filter>
</defs>
</svg>
`;

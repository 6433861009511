export default `
<svg width="225" height="20" viewBox="0 0 225 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3117_23162)">
<path d="M36.4043 18.218C35.8097 17.7043 35.495 17.6016 34.6908 17.6016H0V19.9989H38.5374C37.838 19.3824 37.1387 18.8001 36.4043 18.218Z" fill="white"/>
<path d="M26.9972 9.41917C27.6966 10.0014 28.3961 10.6178 29.0954 11.2H0V8.80273H25.2836C26.088 8.80273 26.3678 8.90548 26.9972 9.41917Z" fill="white"/>
<path d="M0 2.39726H19.6883C18.9889 1.81507 18.2896 1.19864 17.5902 0.616439C16.9606 0.10274 16.646 0 15.8416 0H0V2.39726Z" fill="white"/>
<path d="M65.6045 18.2192C65.0099 17.7055 64.6953 17.6027 63.8909 17.6027H49.4482C43.2583 11.952 37.0686 6.30137 30.8788 0.684932C30.2843 0.10274 29.9695 0 29.1653 0H26.4375C33.1169 6.23288 39.7613 12.4315 46.4407 18.6644C47.6297 19.7602 48.2241 20 49.8678 20H67.7377C67.0032 19.3835 66.3038 18.8014 65.6045 18.2192Z" fill="white"/>
<path d="M102.08 11.2C101.38 10.6178 100.681 10.0014 99.9815 9.41917C99.387 8.90548 99.0724 8.80273 98.268 8.80273H72.9844V11.2H102.08Z" fill="white"/>
<path d="M90.5355 0.616439C91.2349 1.19864 91.9343 1.81507 92.6336 2.39726H72.9453V0H88.8218C89.6262 0 89.9409 0.10274 90.5355 0.616439Z" fill="white"/>
<path d="M72.9844 17.6016H107.675C108.479 17.6016 108.794 17.7043 109.389 18.218C110.088 18.8001 110.787 19.4166 111.487 19.9989H72.9844V17.6016Z" fill="white"/>
<path d="M119.528 2.39726H104.281C103.476 2.39726 103.161 2.29452 102.567 1.78082C101.868 1.19864 101.168 0.582192 100.469 0H138.832V2.39726H122.605V20H119.528V2.39726Z" fill="white"/>
<path d="M176.673 2.9452C174.504 4.89726 172.336 6.84932 170.168 8.76712H144.534V19.9658H147.612V11.1986H168.805C171.287 13.8014 173.77 16.4384 176.287 19.0411C177.023 19.8288 177.442 20 178.526 20H181.078C178.351 17.1575 175.658 14.2808 172.93 11.4384C172.477 10.9589 172.477 10.6849 172.965 10.2397C176.008 7.39726 179.085 4.52055 182.128 1.67808C182.617 1.23288 182.933 0.821918 182.792 0.445206C182.652 0.0684932 182.093 0 181.464 0H144.5V2.39726H176.428C176.952 2.39726 177.057 2.60274 176.673 2.9452Z" fill="white"/>
<path d="M205.344 2.39726H225.033V0H209.191C208.387 0 208.072 0.10274 207.477 0.616439C206.743 1.23288 206.043 1.81507 205.344 2.39726Z" fill="white"/>
<path d="M195.906 11.2H225.002V8.80273H199.718C198.914 8.80273 198.599 8.90548 198.004 9.41917C197.306 10.0014 196.606 10.6178 195.906 11.2Z" fill="white"/>
<path d="M186.5 19.9989H225.037V17.6016H190.346C189.542 17.6016 189.227 17.7043 188.633 18.218C187.898 18.8001 187.199 19.3824 186.5 19.9989Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_3117_23162">
<rect width="225" height="20" fill="white"/>
</clipPath>
</defs>
</svg>

`;

import { canUseDOM, injectScript } from '@/utils/dom-utils';
import { getGlobalConfigs } from '@/utils/site-utils';
import { getQueryStrings } from '@/utils/uri-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { formatDate } from '@/utils/date-utils';
import { equalString } from '@/utils/string-utils';
import CryptoJS from 'crypto-js';
let gtmPromise = null;
let gtmInjected = false;
export const loadGTM = async (page) => {
  if (!gtmInjected) {
    const [gtmUid] = getGlobalConfigs(page, 'gtmUid');
    window.dataLayer = window.dataLayer || [];
    if (!gtmUid?.value) return;
    gtmPromise = injectScript(`https://www.googletagmanager.com/gtm.js?id=${gtmUid?.value}`, () => !!gtmInjected);
    gtmInjected = true;
    await gtmPromise;
    gtmPromise = null;
    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
  }
  if (gtmPromise) {
    await gtmPromise;
  }
  return window.dataLayer;
};
export const gtmPush = (data) => {
  if (!canUseDOM()) return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};
export function gtag() {
  if (!canUseDOM()) return;
  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line no-undef
  window.dataLayer.push(arguments);
}
export const gtmCheckout = (page, step, features, event, currency) => {
  const [filterId, transferCode] = getQueryStrings('filter_id', 'transfer_code');
  let region = 'GB',
    language = 'en';
  if (page.itemLanguage !== 'en') {
    [language, region] = page.itemLanguage.split('-');
  }
  const options = [];
  for (let feature of features) {
    for (let info of feature.infos) {
      options.push({
        option_id: info.code,
        option_name: info.label,
        option_category: feature.type,
        option_price: info.price?.price
      });
    }
  }
  gtmPush({
    event,
    checkout_step: step,
    filter_id: filterId,
    transfer_code: transferCode,
    options,
    region,
    language,
    currency: currency
  });
};

const getGtmUserInfo = (userInfo) => {
  let userId = null;
  let hashEmail = null;
  let hashPhone1 = null;
  let hashPhone2 = null;
  if (!isNullOrEmpty(userInfo?.lotusId)) {
    userId = userInfo?.lotusId;
  }
  if (!isNullOrEmpty(userInfo?.email)) {
    hashEmail = CryptoJS.SHA256(userInfo?.email?.trim().toLowerCase()).toString();
  }
  if (!isNullOrEmpty(userInfo?.mobileAreaCode) && !isNullOrEmpty(userInfo?.phone)) {
    let phoneNum = '';
    if (equalString('0', userInfo.phone.slice(0, 1))) {
      phoneNum = userInfo.phone.substring(1);
    } else {
      phoneNum = userInfo.phone;
    }
    hashPhone1 = CryptoJS.SHA256(`${userInfo?.mobileAreaCode?.trim()}${phoneNum.trim()}`).toString();
    hashPhone2 = CryptoJS.SHA256(`+${userInfo?.mobileAreaCode?.trim()}${phoneNum.trim()}`).toString();
  }
  return {
    user_id: userId,
    user_emid: hashEmail,
    user_phid_f1: hashPhone1,
    user_phid_f2: hashPhone2
  };
};

export const gtmLogin = (userInfo) => {
  gtmPush({
    event: 'login',
    user_data: getGtmUserInfo(userInfo)
  });
};

export const gtmFormView = (formType, formId) => {
  gtmPush({
    form_details: null,
    leads: null,
    external_lead_tracking: null
  });
  gtmPush({
    event: 'form_view',
    form_name: formType,
    form_type: formType,
    form_id: formId,
    form_step_number: '1'
  });
};

export const gtmFormStart = (formType, formId) => {
  gtmPush({
    form_details: null,
    leads: null,
    external_lead_tracking: null
  });
  gtmPush({
    event: 'form_start',
    form_name: formType,
    form_type: formType,
    form_id: formId,
    form_step_number: '1'
  });
};

export const gtmFormStep = (formType, formId, userInfo, stepNumber, stepName, leadsInfo) => {
  gtmPush({
    form_details: null,
    leads: null,
    external_lead_tracking: null
  });
  gtmPush({
    event: 'form_step',
    form_name: formType,
    form_type: formType,
    form_id: formId,
    form_step_number: stepNumber + '',
    form_step_name: stepName,
    user_data: getGtmUserInfo(userInfo),
    leads: leadsInfo,
    external_lead_tracking: {
      lead_id: null,
      account_id: null,
      opportunity_id: null
    }
  });
};

export const gtmFormSubmit = (formType, formId, userInfo, formInfo, leadsInfo) => {
  gtmPush({
    form_details: null,
    leads: null,
    external_lead_tracking: null
  });
  gtmPush({
    event: 'form_submit',
    form_name: formType,
    form_type: formType,
    form_id: formId,
    user_data: getGtmUserInfo(userInfo),
    form_details: formInfo,
    leads: leadsInfo,
    external_lead_tracking: {
      lead_id: null,
      account_id: null,
      opportunity_id: null
    }
  });
};

export const gtmShopOrder = (userInfo, currency, orderId, totalPrice, items) => {
  gtmPush({
    event: 'purchase',
    user_data: getGtmUserInfo(userInfo),
    ecommerce: {
      currency: currency,
      transaction_id: orderId,
      transaction_type: 'lotus_shop',
      value: totalPrice,
      items: items
    }
  });
};

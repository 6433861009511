export default `
<svg width="154" height="153" viewBox="0 0 154 153" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_b_908_8975)">
<circle cx="77.2383" cy="76.4999" r="76.5" fill="black" fill-opacity="0.2"/>
</g>
<g filter="url(#filter1_d_908_8975)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M57.2422 43.8771L55.7383 42.9999V44.7409V108.741V110.51L57.2539 109.598L111.254 77.0977L112.7 76.2275L111.242 75.3771L110.67 75.0435L108.738 76.2922L108.726 76.2851L57.7383 106.972V46.482L103.825 73.3658L105.839 72.2254L57.2422 43.8771Z" fill="#FFF200"/>
</g>
<defs>
<filter id="filter0_b_908_8975" x="-14.2617" y="-15.0001" width="183" height="183" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_908_8975"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_908_8975" result="shape"/>
</filter>
<filter id="filter1_d_908_8975" x="35.7383" y="22.9999" width="96.9609" height="107.51" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="10"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.8 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_908_8975"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_908_8975" result="shape"/>
</filter>
</defs>
</svg>
`;

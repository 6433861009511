import { getAlpha2CodeByLang, getCurrentAlpha2Code, getGlobalCfgValues, getGlobalConfigs, getPageAlpha2Code } from '@/utils/site-utils';
import { ifNullOrEmpty, isArray, isNullOrEmpty, isString } from '@/utils/obj-utils';
import { APP_CONST, CAMERA_ID, FILTER_ENVIRONMENTS, FILTER_NAMES } from '@/utils/constants';
import { merge } from 'lodash';
import lzString from 'lz-string';
import { concatUrls, getLangFromUrl } from '@/utils/uri-utils';
import api from '@/api';
import { equalString, formatString, ifEmpty } from '@/utils/string-utils';
import { canUseDOM } from '@/utils/dom-utils';
import utils from '@/utils/index';

export const analyzeFeatures = (data, unlimited = false) => {
  let countryCode = getCurrentAlpha2Code();
  let currency = null;
  const features = [];
  const priceIds = [];
  const featureCodes = [];
  const packageList = [];
  const buttonFeatureTypes = [];
  const accePriceIds = [];
  const getPackageFeature = (options) => {
    if (isNullOrEmpty(options?.nextLevels)) return [];
    const result = [];
    for (let item of options.nextLevels) {
      const { levelName } = item;
      const { priceList, featureName, featureCode, featureDesc } = item.configurationFeature || {};
      const price = isNullOrEmpty(priceList) ? null : priceList.find((x) => x.country === countryCode);
      if (isString(price?.price)) {
        price.price = Number(price?.price) ?? 0;
      }
      if ((item.checked || unlimited) && /^PKG/gi.test(featureCode)) {
        result.push({
          packageName: featureName ?? '',
          packageCode: featureCode,
          packageDesc: featureDesc ?? '',
          packagePrice: price?.price ?? 0
        });
      }
    }
    return result;
  };
  const getPriceIds = (parent, unlimited = false) => {
    const result = [];
    for (let item of parent.nextLevels) {
      const { priceId } = item.configurationFeature || {};
      if (item.leaf && (item.checked || unlimited)) {
        if (!isNullOrEmpty(priceId)) {
          result.push(priceId);
        }
      } else {
        result.push(...getPriceIds(item, unlimited));
      }
    }
    return result;
  };
  const getInnerFeatures = (parent, grandparent) => {
    if (isNullOrEmpty(parent?.nextLevels)) return [];
    const result = [];
    const { levelName: label } = grandparent;
    for (let item of parent.nextLevels) {
      const { levelName } = item;
      const { id, priceId, priceList, featureName, featureCode, featureDesc, buttonFeatureType } = item.configurationFeature || {};
      const priceItem = isNullOrEmpty(priceList) ? 0 : priceList.find((x) => x.country === countryCode && x.priceCategory === 'Sale Price');
      if (isString(priceItem?.price)) {
        priceItem.price = Number(priceItem.price) ?? 0;
        if (isNaN(priceItem.price)) priceItem.price = 0;
      }
      if (!item.leaf) {
        result.push(...getInnerFeatures(item, parent));
      } else if (item.checked || unlimited) {
        result.push({
          id,
          label,
          value: ifNullOrEmpty(featureName, levelName),
          code: featureCode,
          buttonFeatureType,
          description: featureDesc,
          priceId,
          price: priceItem
        });
        if (priceItem?.currency) {
          currency = priceItem.currency;
        }
        if (item.configurationFeature.priceId) {
          priceIds.push(item.configurationFeature.priceId);
        }
      }
      if (levelName === 'Options') {
        packageList.push(...getPackageFeature(item));
      }
    }
    return result;
  };
  if (isArray(data.nextLevels)) {
    for (let level of data.nextLevels) {
      const { id, levelName } = level;
      const { buttonFeatureType } = level.configurationFeature || {};
      if (buttonFeatureType === 'lambda-car-config-accessories') {
        accePriceIds.push(...getPriceIds(level, unlimited));
      }
      const infos = getInnerFeatures(level, data);
      if (infos.length > 0) {
        features.push({
          id,
          type: levelName,
          infos
        });
        featureCodes.push(...infos.map((x) => x.code));
        buttonFeatureTypes.push(...infos.map((x) => x.buttonFeatureType).filter((x) => !isNullOrEmpty(x)));
      }
    }
  } else {
    console.log('failed data', data);
  }
  const filteredPriceIds = [];
  for (let id of priceIds) {
    if (!accePriceIds.some((x) => x === id)) {
      filteredPriceIds.push(id);
    }
  }
  return [currency, features, [...new Set(filteredPriceIds)], [...new Set(accePriceIds)], featureCodes, packageList, buttonFeatureTypes];
};
export const analyzeVehicleFeatures = (data, iStock = true, distinguishAccessory = true) => {
  const accessories = [];
  const features = [];
  for (let x of data.nextLevels) {
    const { buttonFeatureType } = x.configurationFeature ?? {};
    const isAccessories = (distinguishAccessory && /accessories$/gi.test(buttonFeatureType)) || equalString('Optional package', x.levelName);
    const isEquipments = distinguishAccessory && /equip|package$/gi.test(buttonFeatureType);
    const val = getVehicleInnerFeatures(x, isAccessories, iStock, buttonFeatureType);
    if (isAccessories) {
      accessories.push(...val);
    } else if (isEquipments) {
      accessories.push(...val);
      features.push({
        label: x.levelName,
        type: buttonFeatureType,
        infos: val
      });
    } else {
      features.push({
        label: x.levelName,
        type: buttonFeatureType,
        infos: val
      });
    }
  }
  return [features, accessories];
};
export const getVehicleInnerFeatures = (configItem, isAccessories, iStock = true, rootFeatureType) => {
  if (configItem.leaf && (iStock || configItem.checked)) {
    const { lang } = APP_CONST;
    const alpha2Code = getAlpha2CodeByLang(lang);
    const { featureName } = configItem.configurationFeature;
    const { parent: grandpa } = configItem.parent;
    const { buttonFeatureType: parentFeatureType } = grandpa.configurationFeature || {};
    const { featureCode, phoneImg, tabletImg, pcImg, saleLongComment, buttonFeatureType, priceId } = configItem.configurationFeature || {};
    return {
      label: grandpa.levelName,
      value: featureName,
      code: featureCode,
      rootFeatureType,
      buttonFeatureType,
      parentFeatureType,
      phoneImg,
      tabletImg,
      pcImg,
      saleLongComment,
      priceId,
      price: getConfigPrice(configItem, alpha2Code)
    };
  } else {
    const result = [];
    if (isArray(configItem.nextLevels)) {
      for (const l of configItem.nextLevels) {
        l.parent = configItem;
        const temp = getVehicleInnerFeatures(l, isAccessories, iStock, rootFeatureType);
        if (isArray(temp)) {
          result.push(...temp);
        } else if (!isNullOrEmpty(temp)) {
          result.push(temp);
        }
      }
    } else {
      console.log('failed data', configItem);
    }
    return result;
  }
};
export const getButtonFeatureTypes = (features, accessories) => {
  const result = [];
  for (let feature of features) {
    for (let info of feature.infos) {
      if (!isNullOrEmpty(info.buttonFeatureType)) {
        result.push(info.buttonFeatureType);
      }
    }
  }
  for (let accessory of accessories) {
    if (
      !isNullOrEmpty(accessory.buttonFeatureType) &&
      !isNullOrEmpty(accessory.parentFeatureType) &&
      !/equip|package$/gi.test(accessory.parentFeatureType) &&
      !result.includes(accessory.buttonFeatureType)
    ) {
      result.push(accessory.buttonFeatureType);
    }
  }
  result.push(APP_CONST.market.mhpCode);
  return result;
};
export const getFeaturePriceIds = (features) => {
  const result = [];
  for (let feature of features) {
    for (let info of feature.infos) {
      if (!isNullOrEmpty(info.priceId)) {
        result.push(info.priceId);
      }
    }
  }
  return result;
};
export const getFeatureCurrency = (features) => {
  for (let feature of features) {
    for (let info of feature.infos) {
      if (!isNullOrEmpty(info.currency)) {
        return info.currency;
      }
    }
  }
  return null;
};
export const getAccessoryPriceIds = (accessories) => {
  const result = [];
  for (let accessory of accessories) {
    if (!/equip|package$/gi.test(accessory.rootFeatureType) && !isNullOrEmpty(accessory.priceId)) {
      result.push(accessory.priceId);
    }
  }
  return result;
};
export const buildMhpConfig = (page, series, buttonFeatureTypes, carEngine, environment, options) => {
  const config = {
    animations: [],
    environment: { id: environment ?? FILTER_ENVIRONMENTS.Studio, options: [] },
    camera: { id: CAMERA_ID, options: [] },
    config: {
      id: series,
      options: {
        config: [...buttonFeatureTypes]
      }
    }
  };
  if (!isNullOrEmpty(carEngine?.packageCode)) {
    config.config.options.config.push(carEngine?.packageCode);
  }
  return merge(config, options);
};
export const buildModelImgUrl = (page, filterId, series, buttonFeatureTypes, carEngine, environment, options, size = 2048) => {
  const [mhpHost] = getGlobalConfigs(page, 'mhpHost');
  const config = buildMhpConfig(page, series, buttonFeatureTypes, carEngine, environment, options);
  const mhpHostVal = mhpHost?.value?.href ?? 'https://configurator.prd.configurator.eu.lotuscars.com';
  const alpha2Code = getCurrentAlpha2Code();
  return concatUrls(mhpHostVal, `/iod/${alpha2Code}/${series?.toUpperCase()}/${size}/${lzString.compressToEncodedURIComponent(JSON.stringify(config))}`);
};
export const buildModelImgUrlNoBackdrop = (page, series, carEngine, buttonFeatureTypes) => {
  const [mhpHost] = getGlobalConfigs(page, 'mhpHost');
  const mhpHostVal = mhpHost?.value?.href ?? 'https://configurator.prd.configurator.eu.lotuscars.com';
  const alpha2Code = getCurrentAlpha2Code();
  const config = {
    environment: {
      id: 'Studio_Lotus_1',
      thumbCam: 'BS_Config_Ext_Front_34_measurements'
    },
    camera: {
      id: 'BS_Config_Ext_Side_measurements',
      type: ['Ext', 'Thumbnail']
    },
    config: {
      id: series,
      options: {
        config: buttonFeatureTypes
      }
    },
    animations: [],
    meta: {},
    transparentBackground: true
  };
  return concatUrls(mhpHostVal, `/iod/${alpha2Code}/${series.toUpperCase()}/2048/${lzString.compressToEncodedURIComponent(JSON.stringify(config))}`);
};
export const buildMhpUrl = (page, filterId, series, model, buttonFeatureTypes, benefits, environment, options) => {
  const [mhpLink] = getGlobalConfigs(page, 'mhpLink');
  const mhpLinkVal = mhpLink?.value;
  if (isNullOrEmpty(mhpLinkVal)) return null;
  if (canUseDOM()) {
    const referrer = global.document.referrer;
    if (!isNullOrEmpty(referrer)) {
      const referrerUri = new URL(referrer);
      if (new RegExp(`^${referrerUri.origin}`, 'gi').test(mhpLinkVal)) {
        return referrer;
      }
    }
  }
  const config = buildMhpConfig(page, series, buttonFeatureTypes, model, environment, options);
  const formattedUrl = formatString(mhpLinkVal, {
    lang: page.itemLanguage,
    series,
    model
  });
  let url = concatUrls(formattedUrl, lzString.compressToEncodedURIComponent(JSON.stringify(config)));
  if (benefits?.length > 0) {
    url += '?' + benefits.map((x) => `benefit=${x}`).join('&');
  }
  return url;
};
export const analyzeConfiguration = (configuration, needCheck = true) => {
  const lang = getLangFromUrl();
  const alpha2Code = getAlpha2CodeByLang(lang);
  const analyzeOptionConfig = (level, defaultLabel, showDesc) => {
    const result = [];
    const { nextLevels = [] } = level ?? {};
    for (let subLevel of nextLevels) {
      const { id, configurationFeature, leaf, checked } = subLevel || {};
      if (leaf && (!needCheck || checked)) {
        const { priceId, familyEnglishName, featureName, featureCode, buttonFeatureType, saleLongComment, salesRichText, phoneImg, pcImg, padImg, priceList } = configurationFeature || {};
        let label = ifEmpty(defaultLabel, familyEnglishName, featureName);
        let desc = featureName;
        const item = {
          id,
          label,
          code: featureCode,
          desc,
          buttonFeatureType,
          saleLongComment,
          salesRichText,
          priceId,
          price: getConfigPrice(subLevel, alpha2Code),
          priceList,
          phoneImg,
          pcImg,
          padImg
        };
        item.showDesc = showDesc;
        result.push(item);
      } else {
        result.push(...analyzeOptionConfig(subLevel, defaultLabel, showDesc));
      }
    }
    return result;
  };
  const result = [];
  const { nextLevels: rootLevels = [] } = configuration ?? {};
  for (let rootLevel of rootLevels) {
    const { configurationFeature: rootConfigFeature } = rootLevel;
    const { buttonFeatureType, levelName } = rootConfigFeature || {};
    let defaultLabel = null;
    let showDesc = true;
    if (/lambda-car-config-\s*?(software|package|accessories)/gi.test(buttonFeatureType)) {
      defaultLabel = levelName;
      showDesc = false;
    }
    const infos = analyzeOptionConfig(rootLevel, defaultLabel, showDesc);
    if (infos.length > 0) {
      result.push({
        name: rootLevel.levelName,
        buttonFeatureType,
        infos
      });
    }
  }
  return result;
};
export const getPriceIds = (features) => {
  const priceIds = [],
    accPriceIds = [];
  for (let feature of features) {
    const tempIds = feature.infos.map((x) => x.priceId).filter((x) => !isNullOrEmpty(x));
    if (tempIds.length > 0) {
      if (/accessories$/gi.test(feature.buttonFeatureType)) {
        accPriceIds.push(...tempIds);
      } else {
        priceIds.push(...tempIds);
      }
    }
  }
  return [priceIds, accPriceIds];
};
export const getButtonFeatures = (features) => {
  const result = [];
  for (let feature of features) {
    result.push(...feature.infos.filter((x) => !isNullOrEmpty(x.buttonFeatureType)).map((x) => x.buttonFeatureType));
  }
  return result;
};
export const getConfigPrice = (level, alpha2Code) => {
  const { priceList } = level.configurationFeature;
  const price = priceList?.find((x) => equalString(x.country, alpha2Code));
  if (!price) return null;
  let priceNum = Number(price?.price);
  if (isNaN(priceNum)) return null;
  return {
    price: priceNum,
    currency: price?.currency
  };
};
export const getMhpConfigUrlByCode = (page, code) => {
  const [mhpLink] = getGlobalCfgValues(page, ['mhpLink']);
  const mhpHost = ifEmpty(mhpLink?.split('##')[0], 'https://www.lotuscars.com');
  let url = `code/${code}?shortUrl=true`;
  // if (!utils.site.isPrdEnv(mhpHost)) {
  //   url = `${APP_CONST.lang}/${url}`;
  // }
  url = concatUrls(mhpHost, url);
  return url;
};

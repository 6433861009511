const modalAnimationsRtl = {
  'right-skew-in': {
    in: {
      from: {
        transform: 'skewX(-30deg)',
        translateX: '-150%'
      },
      to: {
        transform: 'skewX(0)',
        translateX: 0
      }
    }
  },
  'right-slide-in': {
    in: {
      from: {
        translateX: '-100%',
        translateY: 0,
        ease: 'cubic-bezier(0, 0, 0.2, 1)'
      },
      to: {
        translateX: 0,
        translateY: 0
      }
    }
  },
  'left-slide-in': {
    in: {
      from: {
        translateX: '100%',
        translateY: 0,
        ease: 'cubic-bezier(0, 0, 0.2, 1)'
      },
      to: {
        translateX: 0,
        translateY: 0
      }
    }
  }
};
export default modalAnimationsRtl;

import { canUseDOM, openWindow } from '@/utils/dom-utils';
import * as querystring from 'querystring';
import { equalString, formatString } from '@/utils/string-utils';
import { webStorage } from '@/utils/web-storage';
import { S_LANG, S_LOGIN_INFO } from '@/utils/web-storage-keys';
import config from '@/temp/config';
import { isNullOrEmpty, isNullOrWhitespace } from '@/utils/obj-utils';
import { getGlobalConfigs } from '@/utils/site-utils';
import { decodeData } from './site-utils';
import { APP_CONST } from '@/utils/constants';

export const isAbsoluteUri = (url) => {
  return /^(https?:)?\/\//gi.test(url);
};
export const isMailUri = (url) => {
  return /^mailto:/gi.test(url);
};
export const isTelUri = (url) => {
  return /^tel:/gi.test(url);
};
// export const getCurrentLang = () => {
//   return; //
// };
export const getQueryStrings = (...args) => {
  const result = [];
  const queries = canUseDOM() ? querystring.parse(window.location.search.substring('1')) : {};
  for (let arg of args) {
    const keys = Object.keys(queries);
    const argArr = arg.split(':');
    const argKey = argArr.length > 1 ? argArr.slice(0, argArr.length - 1).join(':') : argArr[0];
    const argType = argArr.length > 1 ? argArr[argArr.length - 1].toLowerCase() : 'string';
    let value = null;
    for (let key of keys) {
      if (equalString(argKey, key)) {
        let targetVal = queries[key];
        switch (argType) {
          case 'number':
            value = Number(targetVal) ?? null;
            break;
          default:
            value = targetVal;
            break;
        }
        break;
      }
    }
    result.push(value);
  }
  return result;
};
export const getQueryDataList = (...args) => {
  const items = getQueryStrings(...args);
  return items.map((x) => decodeData(x));
};
export const appendQuery = (url, _params, removeParams) => {
  if (!url) return null;
  const arr = url.split('?');
  let query = {};
  let path = url;
  if (arr.length === 1) {
    query = _params;
  } else {
    path = arr[0];
    query = Object.assign(querystring.parse(arr[1]), _params ?? {});
  }
  if (removeParams) {
    removeParams.forEach((p) => {
      if (query[p]) {
        delete query[p];
      }
    });
  }
  return `${path}?${querystring.stringify(query)}`;
};
export const getLocalUrl = (url) => {
  if (!canUseDOM()) return url;
  return `${window.location.origin}${url}`;
};
export const concatUrls = (...urls) => {
  let result = '';
  for (let url of urls) {
    if (isNullOrWhitespace(url)) continue;
    if (result === '') result = url;
    else {
      result = `${result.replace(/[/\\]$/gi, '')}/${url.replace(/^[/\\]/gi, '')}`;
    }
  }
  return result;
};
export const getBetterUrl = (url, defaultLang = null, forceAbsolute = false, includeRedirect = true) => {
  if (!canUseDOM() || isNullOrEmpty(url)) return url;
  if (/http:\/\/(mailto|tel):/gi.test(url)) {
    url = url.replace(/http:\/\//gi, '');
  }
  const hashParts = url.split('#');
  if (hashParts.length > 1) {
    url = `${hashParts[0]}#${hashParts[hashParts.length - 1]}`;
  }
  if (isMailUri(url) || isTelUri(url)) return url;
  if (isNullOrWhitespace(url)) return null;
  let newUrl = url;
  if (!defaultLang) defaultLang = APP_CONST.lang;
  const { search } = window.location;
  // const loginInfo = webStorage.get(S_LOGIN_INFO);
  // console.log('newUrl', newUrl);
  // newUrl = formatString(
  //   newUrl,
  //   {
  //     access_token: loginInfo?.token
  //   },
  //   null,
  //   true
  // );
  const query = new URLSearchParams(window.location.search);
  const referrer = query.get('referrer');
  const channel = query.get('channel');
  const redirect_url = query.get('redirect_url');
  if (!isNullOrWhitespace(referrer)) {
    newUrl = appendQuery(newUrl, {
      referrer
    });
  }
  if (!isNullOrWhitespace(channel)) {
    newUrl = appendQuery(newUrl, {
      channel
    });
  }
  if (includeRedirect && !isNullOrWhitespace(redirect_url) && !/[?&]redirect_url=/gi.test(newUrl)) {
    newUrl = appendQuery(newUrl, {
      redirect_url
    });
  }
  if (!/(utm_source=.*?&.*)(&|$)/gi.test(url)) {
    const matches = search.match(/(utm_source=.*?&.*)(&|$)/gi);
    if (matches?.length > 0) {
      let utmParam = matches[0];
      if (utmParam != null) {
        utmParam = utmParam.replace(/&$/, '');
        let char = newUrl.indexOf('?') >= 0 ? '&' : '?';
        newUrl = newUrl + char + utmParam;
      }
    }
  }
  const reg = /^\/(en|([a-z]{2}-[a-z]{2}))(\/|$)/gi;
  if (isAbsoluteUri(newUrl)) {
    const uri = new URL(newUrl);
    let { pathname } = uri;
    if (!equalString(uri.origin, global.location.origin)) return newUrl;
    if (/^\/?code\//.test(pathname)) return newUrl;
    if (!reg.test(pathname)) {
      pathname = concatUrls(`/${defaultLang}`, pathname);
    }
    return concatUrls(uri.origin, `${pathname}${uri.search}${uri.hash}`);
  }
  if (!reg.test(newUrl)) {
    newUrl = concatUrls(`/${defaultLang}`, newUrl);
  }
  if (forceAbsolute) {
    if (!isAbsoluteUri(newUrl)) {
      newUrl = concatUrls(window.location.origin, newUrl);
    }
  }
  newUrl = newUrl.replace(/[\\/]$/gi, '');
  return newUrl;
};
export const getLoginUrl = (page) => {
  const [loginUrl] = getUrls(page, 'loginLink');
  return loginUrl;
};
export const getLogoutUrl = (page) => {
  const [logoutUrl] = getUrls(page, 'logoutLink');
  return logoutUrl;
};
export const getUrls = (page, ...linkNames) => {
  const links = getGlobalConfigs(page, ...linkNames);
  return links.map((link) => link?.value?.href);
};
export const redirectTo = ({ url, target = '_self', router, lang }) => {
  url = getBetterUrl(url, lang);
  if (!isAbsoluteUri(url)) {
    if (router?.push) {
      const [path, queryStr] = url.split('?');
      let query = null;
      try {
        query = querystring.parse(queryStr);
      } catch (ex) {
        console.log(ex);
      }

      router.push({ path, query });
      return;
    }
    if (canUseDOM()) {
      url = concatUrls(window.location.origin, url);
    }
  }
  openWindow(url, target);
};
export const redirectToLogin = (page, router) => {
  const url = appendQuery(getLoginUrl(page), {
    redirect_url: window.location.href
  });
  redirectTo({ url, router });
};
export const redirectToLogout = (page, router) => {
  const url = appendQuery(getLogoutUrl(page));
  redirectTo({ url, router });
};
export const redirectToLang = (lang, router) => {
  if (!canUseDOM()) return;
  const reg = /^\/(en|([a-z]{2}-[a-z]{2}))(\/|$)/gi;
  const { pathname, search, hash } = window.location;
  const url = getBetterUrl(`${pathname.replace(reg, '/')}${search}${hash}`, lang);
  redirectTo({ url, router, lang });
};
export const getLangFromUrl = () => {
  if (!canUseDOM()) return 'en';
  const { pathname } = window.location;
  const path = pathname.split('/')[1];
  const matches = /([a-z]{2}-[A-Z]{2})|en/i.exec(path);
  if (matches?.length > 0) {
    return matches[0];
  }
  return 'en';
};

export const UnicodeEnc = (str) => {
  let temp = '';
  const strArr = Array.from(str);
  const re = new RegExp('^[0-9a-zA-Z_]+$');
  strArr.forEach((s, i) => {
    if (re.test(s)) {
      temp += s;
    } else {
      temp += ('0000' + parseInt(str.charCodeAt(i)).toString(16)).substr(-4);
    }
  });
  return temp;
};

export const goBack = (router) => {
  const { lang } = APP_CONST;
  const route = router.currentRoute.value;
  const { domain } = global.location;
  const [redirectFrom] = getQueryStrings('redirect_from');
  if (!isNullOrEmpty(redirectFrom)) {
    window.location = redirectFrom;
    return;
  }
  if (!isNullOrEmpty(route.redirectedFrom?.fullPath)) {
    router.back();
  } else if (/^https:\/\/configurator\.lotus/gi.test(document.referrer) || new RegExp(`^${domain}`, 'gi').test(document.referrer)) {
    window.location = document.referrer;
  } else {
    router.replace({ path: `/${lang}` });
  }
};
export const getRedirectFromUrl = (router) => {
  const route = router.currentRoute.value;
  if (!isNullOrEmpty(route.redirectedFrom?.fullPath)) {
    return route.redirectedFrom?.fullPath;
  }
  if (canUseDOM()) {
    if (!isNullOrEmpty(global.document.referrer) && global.document.referrer !== global.location.href) {
      return global.document.referrer;
    }
    if (!isNullOrEmpty(global.opener?.document.URL)) {
      return global.opener?.document.URL;
    }
  }
  return null;
};

export default `
<svg width="154" height="153" viewBox="0 0 154 153" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_b_908_8981)">
<circle cx="77.2383" cy="76.4999" r="76.5" fill="black" fill-opacity="0.2"/>
</g>
<g filter="url(#filter1_d_908_8981)">
<path d="M83.7383 36.2499L71.7383 48.8784L83.7383 62.2499" stroke="#FFF200" stroke-width="2"/>
<path d="M47.7383 78.9999C47.7383 95.5684 61.1697 109 77.7383 109C94.3068 109 107.738 95.5684 107.738 78.9999C107.738 62.4313 94.3068 48.9999 77.7383 48.9999C74.6027 48.9999 72.7383 48.9999 72.7383 48.9999" stroke="#FFF200" stroke-width="2"/>
</g>
<defs>
<filter id="filter0_b_908_8981" x="-14.2617" y="-15.0001" width="183" height="183" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_908_8981"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_908_8981" result="shape"/>
</filter>
<filter id="filter1_d_908_8981" x="26.7383" y="15.561" width="102" height="114.439" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="10"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.8 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_908_8981"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_908_8981" result="shape"/>
</filter>
</defs>
</svg>
`;

export default `
<svg width="64" height="60" viewBox="0 0 64 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_908_8998)">
<path d="M36.7383 20.9999H42.7383V26.9999" stroke="white" stroke-width="2"/>
<path d="M42.7383 32.9999V38.9999H36.7383" stroke="white" stroke-width="2"/>
<path d="M27.7383 20.9999H21.7383V26.9999" stroke="white" stroke-width="2"/>
<path d="M21.7383 32.9999V38.9999H27.7383" stroke="white" stroke-width="2"/>
</g>
<defs>
<filter id="filter0_d_908_8998" x="-3.26172" y="-5.00012" width="70" height="70" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="10"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.8 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_908_8998"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_908_8998" result="shape"/>
</filter>
</defs>
</svg>
`;

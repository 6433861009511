import { loadGMap, getGlobalConfigs } from './site-utils';

export const initStoreMap = async (container, page, store, iconField) => {
  const gmaps = await loadGMap(page);
  const [gmapStyleId] = getGlobalConfigs(page, 'gmapStyleId');
  if (!gmaps) return null;
  let { id, latitude, longitude, storeCode, storeType } = store;
  latitude = Number(latitude);
  longitude = Number(longitude);
  let mapOptions = {
    mapId: gmapStyleId?.value,
    mapTypeId: gmaps.MapTypeId.ROADMAP,
    mapTypeControl: false,
    fullscreenControl: false,
    zoom: 16,
    center: { lat: latitude, lng: longitude }
  };
  const map = new gmaps.Map(container, mapOptions);
  let icon = `data:image/svg+xml;base64,${window.btoa(`${iconField.value.svgCode}`)}`;
  const marker = new gmaps.Marker({
    position: new gmaps.LatLng(latitude, longitude),
    icon,
    map,
    code: storeCode,
    type: storeType,
    id
  });
  new gmaps.Marker({ position: marker.position, icon, map });
  return map;
};

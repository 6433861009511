export default `
<svg width="66" height="65" viewBox="0 0 66 65" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_908_8992)">
<path d="M34.875 22.9999L30.875 27.3713L34.875 31.9999" stroke="white" stroke-width="2"/>
<path d="M25.7383 34.4303C25.7383 38.4728 29.0154 41.7499 33.0579 41.7499C37.1004 41.7499 40.3775 38.4728 40.3775 34.4303C40.3775 30.3878 37.1004 27.1107 33.0579 27.1107C32.2928 27.1107 31.8379 27.1107 31.8379 27.1107" stroke="white" stroke-width="2"/>
</g>
<defs>
<filter id="filter0_d_908_8992" x="0.738281" y="-0.00012207" width="65" height="65" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="10"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.8 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_908_8992"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_908_8992" result="shape"/>
</filter>
</defs>
</svg>
`;

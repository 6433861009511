import { BREAKPOINTS, REMOTE_MODULE_PREFIX } from '@/utils/constants';
import { nextTick } from 'vue';
import Md5 from 'crypto-js/md5';
import mobile from 'is-mobile';
import { isFn, isNullOrEmpty, isNull, isObject } from '@/utils/obj-utils';
import config from '../temp/config';
import { concatString } from '@/utils/string-utils';
import { merge } from 'lodash';
import setPublicPath from '@vue/cli-service/lib/commands/build/setPublicPath';
import utils from '@/utils/index';

export const canUseDOM = () => !!global?.document;
export const getScrollbarWidth = () => {
  const container = document.createElement('div');
  document.body.appendChild(container);
  container.style.overflow = 'scroll';
  const inner = document.createElement('div');
  container.appendChild(inner);
  const width = container.offsetWidth - inner.offsetWidth;
  document.body.removeChild(container);
  return width;
};
export const loadImage = (src, crossOrigin = 'anonymous') => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = crossOrigin;
    img.onload = (e) => {
      resolve(e);
    };
    img.onerror = (e) => {
      reject(e);
    };
  });
};
export const isMobile = () => {
  if (!canUseDOM()) return true;
  if (global.innerWidth) {
    return global.innerWidth < BREAKPOINTS.mobile;
  }
  return mobile();
};
export const isTablet = () => {
  if (!canUseDOM()) return false;
  if (global.innerWidth) {
    return global.innerWidth >= BREAKPOINTS.mobile && global.innerWidth < BREAKPOINTS.tablet;
  }
  return mobile({ tablet: true });
};
export const isTabletLandscape = () => {
  if (!canUseDOM()) return false;
  if (global.innerWidth) {
    return global.innerWidth >= BREAKPOINTS.tablet && global.innerWidth < BREAKPOINTS.desktop;
  }
  return false;
};
export const isDesktop = () => {
  if (!canUseDOM()) return false;
  if (global.innerWidth) {
    return global.innerWidth >= BREAKPOINTS.desktop && global.innerWidth < BREAKPOINTS.desktopLarge;
  }
  return false;
};
export const isDesktopLarge = () => {
  if (!canUseDOM()) return false;
  if (global.innerWidth) {
    return global.innerWidth >= BREAKPOINTS.desktopLarge;
  }
  return false;
};
export const largeThanMobile = () => {
  if (!canUseDOM()) return false;
  if (global.innerWidth) {
    return global.innerWidth >= BREAKPOINTS.mobile;
  }
  return false;
};
export const largeThanTablet = () => {
  if (!canUseDOM()) return false;
  if (global.innerWidth) {
    return global.innerWidth >= BREAKPOINTS.tablet;
  }
  return false;
};
export const largeThanTabletLandscape = () => {
  if (!canUseDOM()) return false;
  if (global.innerWidth) {
    return global.innerWidth >= BREAKPOINTS.desktop;
  }
  return false;
};
export const injectScript = (src, prediction, async = true) => {
  return new Promise((resolve) => {
    if (isNullOrEmpty(src)) resolve(false);
    if (isFn(prediction) && prediction()) {
      resolve(true);
    } else {
      const sc = document.createElement('script');
      sc.async = async;
      sc.src = src;
      sc.addEventListener('load', () => {
        resolve(true);
      });
      sc.addEventListener('error', () => {
        resolve(false);
      });
      document.querySelector('head').appendChild(sc);
    }
  });
};
export const getScrollTop = () => {
  const [, scrollTop] = getScrollingPosition();
  return scrollTop;
};
export const toErrorElement = async (container) => {
  await nextTick();
  const el = container.querySelector('.has-error');
  el.scrollIntoView();
  await sleep(100);
  hideHeader();
};

export const toElement = (el) => {
  if (el) {
    const { top } = el.getBoundingClientRect();
    const bodyRect = document.body.getBoundingClientRect();
    window.scrollTo({
      behavior: 'smooth',
      top: top - bodyRect.top - 80
    });
  }
};
export const appendTextToHtmlEnd = (html, text) => {
  if (!canUseDOM() || !html || !text) return;
  let div = document.createElement('div');
  div.innerHTML = html;
  let node = null;
  for (let i = 0, len = div.children.length; i < len; i++) {
    let n = div.children.item(i);
    if (n.nodeType === 1) {
      if (!['img', 'br', 'hr', 'svg'].includes(n.nodeName.toLowerCase())) {
        node = n;
      }
    }
  }
  if (node) {
    node.appendChild(document.createTextNode(text));
  }
  return div.innerHTML;
};

export const getScrollingPosition = () => {
  const scrollLeft = window.pageXOffset ?? document.documentElement.scrollLeft ?? document.body.scrollLeft;
  const scrollTop = window.pageYOffset ?? document.documentElement.scrollTop ?? document.body.scrollTop;
  return [scrollLeft, scrollTop];
};

export const getViewportSize = () => {
  const width = window.innerWidth ?? document.documentElement.clientWidth ?? document.body.clientWidth;
  const height = window.innerHeight ?? document.documentElement.clientHeight ?? document.body.clientHeight;
  return [width, height];
};
export const getCursorPositionOfPage = (event) => {
  event = event ?? window.event;
  let position = [0, 0];
  if (event.pageX) {
    position = [event.pageX, event.pageY];
  } else if (event.touches) {
    const touchPoint = event.touches[0];
    position = [touchPoint.pageX, touchPoint.pageY];
  } else {
    const [scrollLeft, scrollTop] = getScrollingPosition();
    position = [event.clientX + scrollLeft, event.clientY + scrollTop];
  }
  return position;
};
export const getCursorPosition = (event) => {
  event = event ?? window.event;
  let position = [0, 0];
  if (event.clientX) {
    position = [event.clientX, event.clientY];
  } else if (event.touches) {
    const touchPoint = event.touches[0];
    position = [touchPoint.clientX, touchPoint.clientY];
  } else {
    const [scrollLeft, scrollTop] = getScrollingPosition();
    position = [event.pageX - scrollLeft, event.pageY - scrollTop];
  }
  return position;
};
export const openWindow = (url, target) => {
  if (!url) return;
  if (!target) target = '_self';
  const a = document.createElement('A');
  a.href = url;
  a.target = target;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
export const nodeListToArray = (list) => {
  if (!list?.length) return [];
  return Array.from({ length: list.length }, (index) => list.item(index));
};
export const reverseNodeList = (list) => {
  const array = nodeListToArray(list).reverse();
  return new NodeList(array);
};
export const selectImage = (options) => {
  const el = document.createElement('input');
  el.type = 'file';
  el.style.display = 'none';
  el.accept = options?.accept || 'image/jpeg, image/png';
  el.multiple = options?.multiple;
  return new Promise((resolve) => {
    el.addEventListener(
      'click',
      () => {
        let loop_count = 0; // 轮询次数
        (function loop() {
          if (el.files && el.files.length > 0) {
            resolve(el.files);
            el.remove();
          } else if (++loop_count >= 1000) {
            resolve([]);
            el.remove();
          } else {
            setTimeout(loop, 20);
          }
        })();
      },
      { once: true }
    );
    document.body.appendChild(el);
    el.click();
  });
};
export const readAsDataUrl = (blob) => {
  return new Promise((resolve) => {
    const fr = new FileReader();
    fr.addEventListener(
      'load',
      (e) => {
        resolve([e.target.result, null]);
      },
      { once: true }
    );
    fr.addEventListener(
      'error',
      (ex) => {
        resolve([null, ex]);
      },
      { once: true }
    );
    fr.readAsDataURL(blob);
  });
};
export const appendMeta = (data, uniqueProp, nextMetaSelector) => {
  const checkValue = data[uniqueProp];
  let meta = document.querySelector(`meta[${uniqueProp}='${checkValue}']`);
  if (!meta) {
    const nextMeta = document.querySelector(nextMetaSelector);
    meta = document.createElement('meta');
    if (nextMeta) {
      document.head.insertBefore(meta, nextMeta);
    } else {
      document.head.appendChild(meta);
    }
  }
  const keys = Object.keys(data);
  for (let key of keys) {
    meta.setAttribute(key, data[key]);
  }
};
export const sleep = (duration) => {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
};
export const getOS = () => {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return 'android';
  } else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    return 'ios';
  }
  return 'windows';
};
export const getDeviceInfo = () => {
  return { browser: getBrowserInfo(), os: getOsInfo() };
};
export const getOsInfo = () => {
  let os = { name: 'Unknown', version: '0' };
  if (!canUseDOM()) return os;
  const { userAgent } = window.navigator;
  let macReg = /Mac OS X [0-9_]+/gi,
    iphoneReg = /iPhone OS [0-9_]+/gi,
    ipadReg = /iPad; CPU OS [0-9_]+/gi,
    androidReg = /Android [0-9.]+/gi,
    winReg = /Windows NT [0-9.]+/gi;
  let osMatch = userAgent.match(macReg);
  if (osMatch) {
    const [, , , version] = osMatch[0].split(' ');
    os.name = 'Mac';
    os.version = version.replace(/_/gi, '.');
    return os;
  }
  osMatch = userAgent.match(iphoneReg);
  if (osMatch) {
    const [, , version] = osMatch[0].split(' ');
    os.name = 'iOS';
    os.version = version.replace(/_/gi, '.');
    return os;
  }
  osMatch = userAgent.match(ipadReg);
  if (osMatch) {
    const [, , , version] = osMatch[0].split(' ');
    os.name = 'iPad';
    os.version = version.replace(/_/gi, '.');
    return os;
  }
  osMatch = userAgent.match(androidReg);
  if (osMatch) {
    const [, version] = osMatch[0].split(' ');
    os.name = 'Android';
    os.version = version;
    return os;
  }
  osMatch = userAgent.match(winReg);
  if (osMatch) {
    const [, , version] = osMatch[0].split(' ');
    os.name = 'Windows';
    os.version = version;
  }
  return os;
};
export const getBrowserInfo = () => {
  let browser = { name: 'Unknown', version: '0' };
  if (!canUseDOM()) return browser;
  const { userAgent } = window.navigator;
  const firefoxReg = /Firefox\/[0-9.]+/gi,
    edgeReg = /Edg\/[0-9.]+/gi,
    chromeReg = /Chrome\/[0-9.]+/gi,
    safariReg = /Safari\/[0-9.]+/gi,
    ieReg = /rv:[0-9.]+/gi;
  let matched = userAgent.match(ieReg);
  if (matched) {
    const [, version] = matched[0].split(':');
    browser.name = 'MSIE';
    browser.version = version;
    return browser;
  }
  if (/(Macintosh|iPad|iPhone);/gi.test(userAgent)) {
    matched = userAgent.match(safariReg);
    if (matched) {
      const [name, version] = matched[0].split('/');
      browser.name = name;
      browser.version = version;
      return browser;
    }
  }
  matched = userAgent.match(firefoxReg) ?? userAgent.match(edgeReg) ?? userAgent.match(chromeReg);
  if (matched) {
    const [name, version] = matched[0].split('/');
    browser.name = name;
    browser.version = version;
  }
  return browser;
};
export const getOffsetTop = (node) => {
  if (!node) return 0;
  let offsetTop = node.offsetTop;
  let parentNode = node.parentNode;
  while (parentNode !== null && parentNode !== document.documentElement) {
    offsetTop += parentNode.offsetTop;
    parentNode = parentNode.parentNode;
  }
  return offsetTop;
};
export const getPageHeight = () => {
  return document.documentElement.scrollHeight || document.body.scrollHeight;
};
const modulePromises = {};
export const loadRemoteModule = (url, name) => {
  if (isNullOrEmpty(name)) {
    const urlParts = url.split('/');
    [name] = urlParts[urlParts.length - 1].split('.');
  }
  let moduleName = `${REMOTE_MODULE_PREFIX}_${name}`;
  let innerName = name.replace(/-/gi, '_');
  let moduleNameInWindow = `${REMOTE_MODULE_PREFIX}_${innerName}_${Md5(innerName).toString()}`;
  if (!modulePromises[moduleName]) {
    modulePromises[moduleName] = new Promise((resolve) => {
      if (window[moduleNameInWindow]) {
        resolve({
          name: moduleName,
          code: window[moduleNameInWindow]
        });
      } else {
        const sc = document.createElement('script');
        sc.type = 'module';
        sc.async = true;
        sc.innerText = `import ${moduleNameInWindow} from '${url}'; window.${moduleNameInWindow} = ${moduleNameInWindow};`;
        let delay = 100,
          timing = 0;
        const interval = setInterval(() => {
          if (window[moduleNameInWindow]) {
            resolve({
              name: moduleName,
              code: window[moduleNameInWindow]
            });
            clearInterval(interval);
            delete modulePromises[moduleName];
            return;
          }
          timing += delay;
          if (timing >= 10000) {
            clearInterval(interval);
            delete modulePromises[moduleName];
            resolve(null);
          }
        }, delay);
        document.querySelector('head').appendChild(sc);
      }
    });
  }
  return modulePromises[moduleName];
};
export const outOfElement = (el, x, y) => {
  const rect = el.getBoundingClientRect();
  return x < rect.left || x > rect.left + rect.width || y < rect.top || y > rect.top + rect.height;
};
export const scrollToFirstFormError = async () => {
  await toErrorElement(document.body);
};
export const addModalClass = (className) => {
  if (document.body.scrollHeight > window.innerHeight) {
    document.documentElement.style.paddingRight = '5px';
  }
  document.documentElement.classList.add(className);
};
export const removeModalClasses = (...classNames) => {
  document.documentElement.style.paddingRight = '';
  document.documentElement.classList.remove(...classNames);
};
export const tryParseJson = (data, defaultValue) => {
  try {
    return JSON.parse(data);
  } catch {
    return defaultValue ?? null;
  }
};

export const names = (value) => {
  let splits = value.split('.');
  let obj = global;
  let count = 0;
  while (obj && count < splits.length) {
    obj = obj[splits[count]];
    count++;
  }
  return obj;
};

export const appendDataStructure = (value, id) => {
  const scId = `ds_${id}`;
  let sc = document.getElementById(scId);
  if (!sc) {
    sc = document.createElement('script');
    sc.id = scId;
    document.querySelector('head').appendChild(sc);
  }
  sc.type = 'application/ld+json';
  sc.innerText = JSON.stringify(value);
};
export const loadStyle = (href) => {
  if (!canUseDOM()) return;
  const id = Md5(href).toString();
  let styleEl = document.getElementById(id);
  if (!styleEl) {
    styleEl = document.createElement('link');
    styleEl.id = id;
    styleEl.rel = 'stylesheet';
    styleEl.href = href;
    document.head.appendChild(styleEl);
  }
};
export const loadChineseFont = () => {
  loadStyle(concatString(['/dist/portal-site', config.app.web.folder ?? '', 'css/chinese.css'], '/'));
};
export const getFileName = (filePath) => {
  if (isNullOrEmpty(filePath)) return null;
  const paths = filePath.split(/[/\\]/gi);
  return decodeURIComponent(paths[paths.length - 1]);
};
export const getFileNameNoExt = (filePath) => {
  const fileName = getFileName(filePath);
  if (isNullOrEmpty(fileName)) return null;
  const pair = fileName.split('.');
  const nameArr = pair.slice(0, pair.length - 2);
  return nameArr.join('.');
};
export const refreshPage = () => {
  if (!canUseDOM()) return;
  const { href } = global.location;
  global.location.href = href;
};
export const getCurrentPosition = async (options = null) => {
  if (!canUseDOM()) return [null, null];
  const opts = merge(
    {
      enableHighAccuracy: true,
      timeout: 30000,
      maximumAge: 0
    },
    options
  );
  return new Promise((resolve) => {
    const onGeoSuccess = (res) => resolve([res, null]);
    const onGeoError = (err) => resolve([null, err]);
    global.navigator.geolocation.getCurrentPosition(onGeoSuccess, onGeoError, opts);
  });
};
export const isTouchable = () => {
  if (!canUseDOM()) return false;
  return 'ontouchstart' in global || global.navigator.maxTouchPoints > 0 || global.navigator.msMaxTouchPoints > 0;
};
export const isMouseSupported = () => {
  if (!canUseDOM()) return false;
  let element = document.createElement('div');
  const eventName = 'onmousemove';
  let isSupported = eventName in element;
  if (!isSupported) {
    element.setAttribute(eventName, 'return;');
    isSupported = typeof element[eventName] == 'function';
  }
  element = null;
  return isSupported;
};
export const setMetaData = (name, data) => {
  if (!canUseDOM()) return false;
  const metas = document.getElementsByTagName('meta');
  const targetMeta = metas[name];
  if (isNull(targetMeta)) {
    let newMeta = document.createElement('meta');
    newMeta.setAttribute('name', name);
    newMeta.setAttribute('content', data);
    document.getElementsByTagName('head')[0].appendChild(newMeta);
  } else {
    targetMeta.setAttribute('content', data);
  }
};
export const tweenAni = (from, to, duration, animation, callback) => {
  return new Promise((resolve) => {
    let result = from;
    const runAni = (progress, step, speed) => {
      if (result >= to) {
        resolve();
        return;
      }
      result = animation(0, from, to, duration);
      callback(result);
      progress += speed * step;
    };
    runAni(0, 1 / duration, 20);
  });
};
export const scrollToEl = async (el, duration = 336) => {
  if (!canUseDOM() || isNullOrEmpty(el)) return;
  const scrollTop = getScrollTop();
  const rect = el.getBoundingClientRect();
  const $header = global.document.querySelector('.c-header');
  const headerHeight = $header.scrollHeight ?? 0;
  const top = scrollTop + rect.top - headerHeight;
  await tweenAni(scrollTop, top, duration, utils.tween.linear, (result) => {
    global.document.body.scrollTop = result;
  });
};
export const getParentEl = (el, predicate) => {
  let parentEl = el?.parentElement,
    i = 0;
  if (isNullOrEmpty(parentEl) || !isFn(predicate)) return null;
  while (!predicate(parentEl) && i < 15) {
    parentEl = parentEl?.parentElement;
    ++i;
  }
  return parentEl;
};
export const hideHeader = () => {
  const $header = document.querySelector('.c-header');
  $header?.classList.add('hide');
};
export const appendToHead = (tag, props) => {
  const el = document.createElement(tag);
  if (isObject(props)) {
    const keys = Object.keys(props);
    for (let key of keys) {
      el.setAttribute(key, props[key]);
    }
  }
  document.head.appendChild(el);
};
export const preload = (href, as) => {
  appendToHead('LINK', {
    rel: 'preload',
    href,
    as
  });
};

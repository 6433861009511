export default `
<svg width="77" height="79" viewBox="0 0 77 79" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_908_8990)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M32.2422 30.8771L30.7383 29.9999V31.7409V47.1123V48.8813L32.2539 47.9691L45.2235 40.1633L46.6695 39.2931L45.2117 38.4427L44.8685 38.2425L42.743 39.3219L32.7383 45.3433V33.482L40.7137 38.1343L42.6708 36.9605L32.2422 30.8771Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_908_8990" x="0.738281" y="-0.00012207" width="75.9297" height="78.8814" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="15"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.8 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_908_8990"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_908_8990" result="shape"/>
</filter>
</defs>
</svg>
`;

import { isPossibleNumber, parsePhoneNumber } from 'libphonenumber-js';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { getLangFromUrl } from '@/utils/uri-utils';

export const validateFunctions = {
  pattern(item, value, validator, showError = true) {
    const lang = getLangFromUrl();
    const { pattern, message } = validator.fields;
    const reg = new RegExp(pattern.value, 'gi');
    if (isNullOrEmpty(pattern)) {
      console.log(`pattern validator dose not have a version on ${lang}, item name: ${item.name}`);
    }
    // if (value === '1111-11-11') {
    //   console.log('validate result', value, reg, reg.test(value));
    // }
    if (!reg.test(value)) {
      if (showError) {
        item.controlProps.errorMsg = message.value;
      }
      return false;
    }
    return true;
  },
  length(item, value, validator, showError = true) {
    const { min, max, message } = validator.fields;
    if (min?.value && value.length < min.value) {
      if (showError) {
        item.controlProps.errorMsg = message.value;
      }
      return false;
    }
    if (max?.value && value.length > max.value) {
      if (showError) {
        item.controlProps.errorMsg = message.value;
      }
      return false;
    }
    return true;
  },
  ['phone-number'](item, value, validator, showError = true) {
    const { message } = validator.fields;
    const phoneNumber = parsePhoneNumber(`+${value?.area?.code}${value?.number}`, {
      defaultCountry: value?.area?.data?.alpha2Code
    });
    const valid = phoneNumber.isPossible();
    if (!valid) {
      if (showError) {
        item.controlProps.errorMsg = message.value;
      }
      return false;
    }
    return true;
  }
};

export default `
<svg width="65" height="60" viewBox="0 0 65 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_908_8996)">
<path d="M25.3497 25.7367C25.3595 25.7367 25.3688 25.7367 25.3786 25.7372L32.4087 21.1932C32.569 21.072 32.7694 20.9999 32.9867 20.9999C33.5138 20.9999 33.9412 21.4241 33.9412 21.9472C33.9412 21.9907 33.9384 22.0333 33.9328 22.0749V37.9248C33.9385 37.9665 33.9412 38.009 33.9412 38.0525C33.9412 38.5757 33.5138 38.9999 32.9867 38.9999C32.7503 38.9999 32.5336 38.9147 32.3672 38.7732L25.3544 34.2667L22.4859 34.263C21.9587 34.263 21.5312 33.8402 21.5312 33.318V26.6818C21.5312 26.1595 21.9545 25.7367 22.4859 25.7367H25.3497Z" stroke="white" stroke-width="2"/>
<path d="M37.9414 35.9999C41.2551 35.9999 43.9414 33.3136 43.9414 29.9999C43.9414 26.6862 41.2551 23.9999 37.9414 23.9999" stroke="white" stroke-width="2"/>
<path d="M38.6914 32.8001C39.8385 32.8001 40.7683 31.5465 40.7683 30.0001C40.7683 28.4537 39.8385 27.2001 38.6914 27.2001V32.8001Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_908_8996" x="-2.26172" y="-5.00012" width="70" height="70" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="10"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.8 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_908_8996"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_908_8996" result="shape"/>
</filter>
</defs>
</svg>
`;

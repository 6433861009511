export default `
<svg width="87" height="80" viewBox="0 0 87 80" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_908_8994)">
<path d="M35.3497 35.7367C35.3595 35.7367 35.3688 35.7367 35.3786 35.7372L42.4087 31.1932C42.569 31.072 42.7694 30.9999 42.9867 30.9999C43.5138 30.9999 43.9412 31.4241 43.9412 31.9472C43.9412 31.9907 43.9384 32.0333 43.9328 32.0749V47.9248C43.9385 47.9665 43.9412 48.009 43.9412 48.0525C43.9412 48.5757 43.5138 48.9999 42.9867 48.9999C42.7503 48.9999 42.5336 48.9147 42.3672 48.7732L35.3544 44.2667L32.4859 44.263C31.9587 44.263 31.5312 43.8402 31.5312 43.318V36.6818C31.5312 36.1595 31.9545 35.7367 32.4859 35.7367H35.3497Z" stroke="white" stroke-width="2"/>
<path d="M48.1484 35.9999L52.1484 39.9999" stroke="white" stroke-width="2"/>
<path d="M54.1484 41.9999L56.1484 43.9999" stroke="white" stroke-width="2"/>
<path d="M56.1484 35.9999L48.1484 43.9999" stroke="white" stroke-width="2"/>
</g>
<defs>
<filter id="filter0_d_908_8994" x="-2.26172" y="-5.00012" width="90" height="90" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="15"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.8 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_908_8994"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_908_8994" result="shape"/>
</filter>
</defs>
</svg>
`;

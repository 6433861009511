import { getGlobalConfigs } from '@/utils/site-utils';
import { merge } from 'lodash';
import { canUseDOM } from '@/utils/dom-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { S_LOGIN_INFO } from '@/utils/web-storage-keys';
import { webStorage } from '@/utils/web-storage';
export const loadQtTracker = (page) => {
  if (!canUseDOM()) return;
  const [sdkId, appKey, host] = getGlobalConfigs(page, 'qtSdkId', 'qtAppKey', 'qtHost');
  const loginInfo = webStorage.get(S_LOGIN_INFO);
  global.aplus_queue = global.aplus_queue || [];
  return new Promise((resolve) => {
    if (isNullOrEmpty(sdkId?.value) || isNullOrEmpty(appKey?.value) || isNullOrEmpty(host?.value)) {
      // console.log('Ali Quick Tracking is not configured properly');
      resolve(false);
    }
    const script = document.createElement('script');
    script.async = true;
    script.id = 'beacon-aplus';
    script.addEventListener('load', () => {
      resolve(true);
    });
    script.src = `https://d.alicdn.com/alilog/mlog/aplus/${sdkId?.value}.js`;
    const head = document.querySelector('head');
    head.appendChild(script);
    qtUtils.setMetaInfo('appKey', appKey?.value);
    qtUtils.setMetaInfo('aplus-rhost-v', host?.value);
    qtUtils.setMetaInfo('aplus-disable-apv', true);
    qtUtils.setMetaInfo('DEBUG', true);
    if (!isNullOrEmpty(loginInfo?.lotusId)) {
      qtUtils.setMetaInfo('_user_id', loginInfo?.lotusId);
    }
    // global.aplus_queue.push({ action: 'aplus.setMetaInfo', arguments: ['appKey', appKey?.value] });
    // global.aplus_queue.push({ action: 'aplus.setMetaInfo', arguments: ['aplus-rhost-v', host?.value] });
    // global.aplus_queue.push({
    //   action: 'aplus.setMetaInfo',
    //   arguments: ['aplus-disable-apv', true]
    // });
    // global.aplus_queue.push({
    //   action: 'aplus.setMetaInfo',
    //   arguments: ['DEBUG', true]
    // });
  });
};
export const qtUtils = {
  track(action, ...params) {
    global.aplus_queue = global.aplus_queue || [];
    global.aplus_queue.push({ action, arguments: params });
  },
  /**
   * @description 埋码参数设置
   * @param metaName 参数名
   * @param metaValue 参数值
   */
  setMetaInfo(metaName, metaValue) {
    return qtUtils.track('aplus.setMetaInfo', metaName, metaValue);
  },
  appendMetaInfo(metaName, metaValue) {
    return qtUtils.track('aplus.appendMetaInfo', metaName, metaValue);
  },
  /**
   * @description 页面浏览事件
   * @param page Sitecore页面
   * @param pvParams 浏览事件参数
   */
  trackScPv: (page, pvParams) => {
    const { qtPageName } = page?.fields || {};
    const qtPageNameVal = qtPageName?.value;
    if (isNullOrEmpty(qtPageNameVal)) return null;
    return qtUtils.trackPv(qtPageNameVal, pvParams);
  },
  /**
   * @description 页面浏览事件
   * @param pvName 浏览页面名称
   * @param pvParams 浏览事件参数
   */
  trackPv: (pvName, pvParams) =>
    qtUtils.track(
      'aplus.sendPV',
      { is_auto: false },
      merge(
        {
          page_name: pvName
        },
        pvParams
      )
    ),
  /**
   * @description 手动点击Sitecore按钮事件
   * */
  trackBtnClick(page, btn, eventParams) {
    const { id, name, fields } = btn ?? {};
    const { text } = fields ?? {};
    const eventCode = qtUtils.getBtnCode(page, btn);
    const { qtPageName } = page?.fields || {};
    const qtPageNameVal = qtPageName?.value;
    if (isNullOrEmpty(eventCode)) return;
    const params = {
      button_id: id,
      button_name: name,
      customized_button_name: text?.value
    };
    if (!isNullOrEmpty(qtPageNameVal)) {
      merge(params, {
        page_name: qtPageNameVal
      });
    }
    merge(params, eventParams);
    return qtUtils.trackCustom(eventCode, 'CLK', params);
  },
  /**
   * @description 手动点击Sitecore按钮事件 - 自定义Code
   * */
  trackBtnClickWithCode(page, btn, eventCode, eventParams) {
    const { id, name, fields } = btn ?? {};
    const { text } = fields ?? {};
    const { qtPageName } = page?.fields || {};
    const qtPageNameVal = qtPageName?.value;
    const params = {
      button_id: id,
      button_name: name,
      customized_button_name: text?.value
    };
    if (!isNullOrEmpty(qtPageNameVal)) {
      merge(params, {
        page_name: qtPageNameVal
      });
    }
    merge(params, eventParams);
    return qtUtils.trackCustom(eventCode, 'CLK', params);
  },
  /**
   * @description 点击事件
   * */
  trackClick(eventCode, eventParams) {
    return qtUtils.trackCustom(eventCode, 'CLK', eventParams);
  },
  /**
   * @description 曝光事件
   * */
  trackExp(eventCode, eventParams) {
    return qtUtils.trackCustom(eventCode, 'EXP', eventParams);
  },
  /**
   * @description 自定义事件
   * @param {String} eventCode 事件编码
   * @param {String} eventType=[CLK|EXP|OTHER] 事件类型, EXP：曝光事件, CLK：点击事件, OTHER：其他事件
   * @param {Object} eventParams 事件参数
   */
  trackCustom: (eventCode, eventType, eventParams) => qtUtils.track('aplus.record', eventCode, eventType, eventParams),
  /**
   * @description 获取按钮事件编码
   * @param {SitePage} page
   * @param {Object} btn
   */
  getBtnCode(page, btn) {
    let result = btn?.fields?.aliQtEventCode?.value;
    if (isNullOrEmpty(result)) {
      result = page?.fields?.qtButtonEventCode?.value;
    }
    return result;
  }
};

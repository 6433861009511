export default `
<svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 13 12">
  <g clip-path="url(#clip0_616_11276)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1793 3.08337L5.04208 8.2206L2.82152 6.00004H0.700195L4.51175 9.81159L5.04208 10.3419L5.57241 9.81159L12.3006 3.08337H10.1793Z"/>
  </g>
  <defs>
    <clipPath id="clip0_616_11276">
      <rect width="11.6667" height="11.6667" fill="white" transform="translate(0.666992 0.166748)"/>
    </clipPath>
  </defs>
</svg>
`;

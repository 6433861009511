const BUS_EVENTS = {
  OPEN_REGION_MODAL: 'open-region-modal',
  OPEN_CHAT_MODAL: 'open-chat-modal',
  UPDATE_HEADER_THEME: 'update-header-theme',
  OPEN_PERSONAL_CENTER_BAR: 'open-personal-center-bar',
  CONFIRM_FINANCE_CALCULATOR: 'confirm-finance-calculator',
  OPEN_FINANCE_CALCULATOR: 'open-finance-calculator',
  TOKEN_EXPIRED: 'token-expired'
};
export default BUS_EVENTS;

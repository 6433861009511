import play from './play';
import pause from './pause';
import location from './location';
import down from './down';
import direction from './direction';
import check from './check';
import close from './close';
import closeCircleSolid from './close-circle-solid';
import cart from './cart';
import config from './config';
import lotus from './lotus';
import left from './left';
import right from './right';
import forward from './forward';
import back from './back';
import caretUp from './caret-up';
import caretDown from './caret-down';
import arrowDown from './arrow-down';
import arrowUp from './arrow-up';
import region from './region';
import search from './search';
import searchB from './search-b';
import skewArrow from './skew-arrow';
import skewArrowBottomRight from './skew-arrow-br';
import trash from './trash';
import arrowRight from './arrow-right';
import eletre from './eletre';
import emira from './emira';
import mute from './mute';
import user from './user';
import logOut from './log-out';
import okBucket from './ok-bucket';
import position from './position';
import download from './download';
import minus from './minus';
import plus from './plus';
import pin from './pin';
import pinHollow from './pin-hollow';
import paperPlane from './paper-plane';
import screen from './screen';
import email from './email';
import phone from './phone';
import phoneLine from './phone-l';
import query from './query';
import target from './target';
import invoiceDownload from './invoice-download';
import important from './important';
import calculator from './calculator';
import download_a from './download_a';
import closeCommon from './close-common';
import replay from './replay';
import person from './person';
import mobile from './mobile';
import filter from './filter';
import notificationFail from './notification-fail';
import notificationWarn from './notification-warn';
import notificationSuccess from './notification-success';
import notificationError from './notification-error';
import warn from './warn';
import pointBack from './point-back';
import playMini from './play-mini';
import pauseMini from './pause-mini';
import replayMini from './replay-mini';
import muteMini from './mute-mini';
import unmuteMini from './unmute-mini';
import fullscreenMini from './fullscreen-mini';
import unFullscreenMini from './unfullscreen-mini';
import hoverPlay from './hover-play';
import hoverReplay from './hover-replay';
import loading from './loading';
const svgIcons = {
  'arrow-down': arrowDown,
  'arrow-up': arrowUp,
  play,
  pause,
  location,
  'pin-hollow': pinHollow,
  'paper-plane': paperPlane,
  down,
  direction,
  cart,
  'caret-up': caretUp,
  'caret-down': caretDown,
  calculator,
  check,
  close,
  'close-circle-solid': closeCircleSolid,
  config,
  lotus,
  left,
  right,
  forward,
  back,
  'close-common': closeCommon,
  search,
  'search-b': searchB,
  'skew-arrow': skewArrow,
  'skew-arrow-br': skewArrowBottomRight,
  'arrow-right': arrowRight,
  eletre,
  emira,
  mute,
  user,
  'log-out': logOut,
  'ok-bucket': okBucket,
  position,
  download,
  minus,
  plus,
  pin,
  screen,
  email,
  phone,
  'phone-l': phoneLine,
  query,
  target,
  'invoice-download': invoiceDownload,
  important,
  'download-a': download_a,
  replay,
  person,
  region,
  trash,
  mobile,
  filter,
  notificationFail,
  notificationWarn,
  notificationSuccess,
  notificationError,
  warn,
  'point-back': pointBack,
  'play-mini': playMini,
  'pause-mini': pauseMini,
  'replay-mini': replayMini,
  'mute-mini': muteMini,
  'unmute-mini': unmuteMini,
  'fullscreen-mini': fullscreenMini,
  'unfullscreen-mini': unFullscreenMini,
  'hover-play': hoverPlay,
  'hover-replay': hoverReplay,
  loading
};
export default svgIcons;

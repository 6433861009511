import { canUseDOM } from '@/utils/dom-utils';
export const webStorage = {
  get(key) {
    if (!canUseDOM()) return null;
    const now = new Date();
    const storedData = localStorage.getItem(key.keyData);
    if (storedData !== null) {
      const dataInStore = JSON.parse(decodeURIComponent(atob(storedData)));
      if (dataInStore.updateTime) {
        if (!key.expireMinutes || now.getTime() - dataInStore.updateTime <= key.expireMinutes * 60 * 1000) {
          return dataInStore.data;
        } else {
          webStorage.clear(key);
          return null;
        }
      } else {
        webStorage.clear(key);
        return null;
      }
    } else {
      return null;
    }
  },
  getThenRemove(key) {
    const result = webStorage.get(key.keyData);
    webStorage.clear(key);
    return result;
  },
  set(key, data) {
    if (!canUseDOM()) return null;
    const now = new Date();
    const dataInStore = {
      data,
      updateTime: now.getTime()
    };
    const storedData = btoa(encodeURIComponent(JSON.stringify(dataInStore)));
    localStorage.setItem(key.keyData, storedData);
  },
  clear(key) {
    if (!canUseDOM()) return null;
    localStorage.removeItem(key.keyData);
  }
};

export default `
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_5_15886)">
<path d="M7.5 11.1819L3.5 7.30311V8.63641L8 13L12.5 8.63631V7.30296L8.5 11.1817V1L7.5 1V11.1819Z"/>
</g>
<path d="M14 12H2V14H14V12Z" mask="url(#path-1-inside-1_5_15886)"/>
<defs>
<clipPath id="clip0_5_15886">
<path d="M2 1H14V13H2V1Z" fill="white"/>
</clipPath>
</defs>
</svg>
`;

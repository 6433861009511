import gsap from 'gsap';

export const transitions = {
  accordion: {
    beforeEnter(el) {
      el.style.overflow = 'hidden';
      el.style.height = 0;
    },
    enter(el, done) {
      el.style.overflow = 'hidden';
      gsap.killTweensOf(el);
      gsap.to(el, {
        height: el.scrollHeight,
        duration: 0.5,
        ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
        onComplete() {
          el.style.overflow = 'visible';
          el.style.height = 'auto';
          done();
        }
      });
    },
    leave(el, done) {
      gsap.killTweensOf(el);
      el.style.overflow = 'hidden';
      gsap.to(el, {
        height: 0,
        duration: 0.5,
        ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
        onComplete() {
          el.style.overflow = 'hidden';
          done();
        }
      });
    }
  }
};

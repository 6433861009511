import api from '@/api';
export const GET_CAPTCHA_SCENE = {
  LOGIN: 'login',
  CHECKOUT: 'checkout',
  RESERVATION: 'reservation'
};
export const getCaptchaExecutor = (captchaScene, isBind = false) => {
  switch (captchaScene) {
    case GET_CAPTCHA_SCENE.LOGIN:
      return [api.cidp.v3.captcha.get, api.cidp.login];
    case GET_CAPTCHA_SCENE.CHECKOUT:
      if (isBind) {
        return [api.cidp.v2.captcha.getBind, api.cidp.v2.captcha.checkBind];
      } else {
        return [api.cidp.v2.captcha.get, api.cidp.captcha.check];
      }
    case GET_CAPTCHA_SCENE.RESERVATION:
      return [api.cidp.v2.captcha.get, api.cidp.captcha.check];
    default:
      return [null, null];
  }
};

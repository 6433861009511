import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import { TweenMax, TimelineMax } from 'gsap';
import { canUseDOM, getScrollTop, injectScript, loadRemoteModule } from '@/utils/dom-utils';
import { getType, isFn, isArray, isNullOrEmpty, isNullOrWhitespace, isObject } from '@/utils/obj-utils';
import { concatString, equalString, formatString, ifEmpty, ifEmptyField, randomString, toCapitalize } from '@/utils/string-utils';
import lzString from 'lz-string';
import { merge, isString } from 'lodash';
import * as querystring from 'querystring';
import { appendQuery, concatUrls, getBetterUrl, getLangFromUrl } from '@/utils/uri-utils';
import { unWrapSitecoreItem } from '@/utils/sitecore-utils';
import { getCurrentInstance } from 'vue';
import { formatDate } from '@/utils/date-utils';
import utils from '@/utils/index';
export const headerHeights = [52, 52, 64];
/**@param {ComponentOverallSetting} props*/
export const overallSettingClasses = (props) => {
  const paddingTop = settingValue(props.fields.paddingTop);
  const paddingBottom = settingValue(props.fields.paddingBottom);
  const marginTop = settingValue(props.fields.marginTop);
  const marginBottom = settingValue(props.fields.marginBottom);
  const theme = settingValue(props.fields.theme);
  return [
    {
      [`overall-pd-top-${paddingTop}`]: paddingTop,
      [`overall-pd-bottom-${paddingBottom}`]: paddingBottom,
      [`overall-mg-top-${marginTop}`]: marginTop,
      [`overall-mg-bottom-${marginBottom}`]: marginBottom,
      [`theme-${theme}`]: theme
    }
  ];
};
export const themeClass = (props, defaultTheme) => {
  const themeVal = settingValue(props?.fields?.theme, defaultTheme);
  return {
    [`theme-${themeVal}`]: themeVal
  };
};
export const settingValue = (item, defaultValue) => {
  let val = item?.fields?.phrase?.value;
  if (isNullOrEmpty(val)) {
    val = defaultValue;
  }
  return val;
};
export const settingVal = (item, defaultItem) => {
  return settingValue(item, defaultItem?.fields?.phrase?.value);
};
export const getPage = () => {
  const { proxy } = getCurrentInstance();
  const { $jss } = proxy;
  return $jss.routeData();
};
export const gridLayoutClass = (item, defaultValue) => {
  const val = settingValue(item, defaultValue);
  return val ? `grid-layout-${val}` : null;
};
let ScrollMagic;
export const loadScrollMagic = () => {
  if (!ScrollMagic) {
    ScrollMagic = require('scrollmagic');
    ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
  }
  return ScrollMagic;
};

export const gridWidth = (span, includeGap = true, columnCount = 24, gap = 8) => {
  if (!canUseDOM()) return 0;
  const perColumnWidth = (window.innerWidth - gap * (columnCount - 1)) / columnCount;
  let result = perColumnWidth * span + (span - 1) * gap;
  if (includeGap) {
    result += gap;
  }
  return Math.floor(result);
};
export const gridWidthMobile = (span, includeGap = true) => {
  return gridWidth(span, includeGap, 12);
};
export const gridPercent = (span) => {
  return Math.floor((1000 * span) / 24) / 10 + 'vw';
};

export const componentId = (rendering) => `com-${rendering.uid}`;

export const classNames = (className, fn) => [className, ...fn];

export const getComponentProps = (props, className) => {
  const { rendering, fields } = props;
  return {
    id: componentId(rendering),
    [`data-source-id`]: rendering.dataSource,
    className: classNames(className, overallSettingClasses(fields))
  };
};

export const renderBullet = (index, className, total) => {
  if (!total) total = 1;
  const radius = 15;
  const size = 34;
  const center = size / 2;
  const anglePerItem = 360 / total;
  const startDegree = anglePerItem * index;
  const endDegree = anglePerItem * (index + 1);
  const startAngle = (startDegree * Math.PI) / 180;
  const endAngle = (endDegree * Math.PI) / 180;
  const startX = center + radius * Math.sin(startAngle);
  const startY = center - radius * Math.cos(startAngle);
  const endX = center + radius * Math.sin(endAngle);
  const endY = center - radius * Math.cos(endAngle);
  return `<div class="${className}"><svg viewBox="0 0 ${size} ${size}"><circle cx="${center}" cy="${center}" r="${radius}" /><path d="M ${startX} ${startY} A ${radius},${radius} 0 0 1 ${endX},${endY}" /></svg></div>`;
};

export const loadGMap = (page) => {
  return new Promise((resolve) => {
    if (!canUseDOM()) {
      resolve(null);
      return;
    }
    if (window.google?.maps) {
      resolve(window.google.maps);
      return;
    }
    const [gmapKey] = getGlobalConfigs(page, 'gmapKey');
    const method = `onGMapReady${Math.ceil(Math.random() * 100000)}${Date.now()}`;
    window[method] = () => {
      resolve(window.google.maps);
    };
    injectScript(`https://maps.google.com/maps/api/js?key=${gmapKey?.value}&libraries=places&callback=${method}&language=${page.itemLanguage}`)
      .then((loaded) => {
        if (!loaded) {
          resolve(null);
        }
      })
      .catch(() => {
        resolve(null);
      });
  });
};

export const loadAMap = (page) => {
  const [amapKey] = getGlobalConfigs(page, 'amapKey');
  return new Promise((resolve) => {
    if (!canUseDOM()) {
      resolve(null);
      return;
    }
    if (window.AMap) {
      resolve(window.AMap);
      return;
    }
    const method = `onAMapReady${Math.ceil(Math.random() * 100000)}${Date.now()}`;
    window[method] = () => {
      resolve(window.AMap);
    };
    injectScript(`https://webapi.amap.com/maps?v=2.0&key=${amapKey?.value}&callback=${method}`)
      .then((loaded) => {
        if (!loaded) {
          resolve(null);
        }
      })
      .catch(() => {
        resolve(null);
      });
  });
};
export const getGlobalConfig = (page, key, defaultValue = null) => {
  const { globalConfig } = page?.fields ?? {};
  const globalConfigFields = globalConfig?.fields ?? {};
  return globalConfigFields[key] ?? defaultValue;
};
export const getGlobalConfigs = (page, ...keys) => {
  let result = [];
  for (let key of keys) {
    result.push(getGlobalConfig(page, key));
  }
  return result;
};
export const getGlobalCfgValues = (page, keys, defaults) => {
  const items = getGlobalConfigs(page, ...keys);
  const result = [];
  for (let i = 0, len = items.length; i < len; i++) {
    const item = items[i];
    const defaultVal = defaults?.length > i ? defaults[i] : null;
    result.push(ifEmpty(item?.value, defaultVal) ?? null);
  }
  return result;
};
export const getMarket = (page) => {
  const [market] = getGlobalConfigs(page, 'market');
  const defaultVal = { phrase: 'EU', code: 'EU', mhpCode: 'MHP_EU_MARKET' };
  if (isNullOrEmpty(market?.fields)) return defaultVal;
  return unWrapSitecoreItem(market);
};
export const isMarket = (page, phrase) => {
  const market = getMarket(page);
  return equalString(market?.phrase, phrase) || equalString(market?.code, phrase);
};
export const handleComClass = (className) => {
  if (!className) return [];
  const classType = getType(className);
  if (classType === '[object Array]') {
    return className;
  } else if (classType === '[object Object]') {
    return [className];
  } else if (classType === '[object String]') {
    return [className];
  }
  return [];
};
export const buildAddress = (...addresses) => {
  return concatString(addresses, ', ', (str) => {
    str = str.replace(/,\s*?,/gi, ',');
    str = str.replace(/,\s*?$/gi, '');
    str = str.replace(/^\s*?,/gi, '');
    return str;
  });
};
export const isLocalServer = () => {
  if (!canUseDOM()) return false;
  return /https?:\/\/localhost/gi.test(window.location.origin);
};
let oneTrustLoaded = false;
export const loadOneTrust = async (page) => {
  try {
    if (!oneTrustLoaded) {
      const [oneTrustScript, oneTrustDomainId] = getGlobalConfigs(page, 'oneTrustScript', 'oneTrustDomainId');
      if (!oneTrustScript?.value || !oneTrustDomainId?.value) return false;
      const head = document.querySelector('head');
      const sc1 = document.createElement('script');
      sc1.type = 'text/javascript';
      sc1.innerHTML = `function OptanonWrapper(){
        var container = document.getElementById('onetrust-banner-sdk');
        if (container) {
          var header = container.querySelector('.banner-header');
          var acceptBtn = document.getElementById("onetrust-accept-btn-handler");
          var declineBtn = document.getElementById("onetrust-reject-all-handler");
          var cookieSettingsBtn = document.getElementById("onetrust-pc-btn-handler");
          var btnContainer = document.getElementById("onetrust-button-group");
          var oldContainer = btnContainer?.querySelector('.banner-actions-container');
          header?.remove();
          oldContainer?.remove();
          btnContainer?.classList.add('onetrust-buttons');
          btnContainer?.append(acceptBtn, declineBtn, cookieSettingsBtn);
        }
      }`;
      head.appendChild(sc1);
      const sc = document.createElement('script');
      sc.type = 'text/javascript';
      sc.src = oneTrustScript?.value;
      sc.async = true;
      sc.setAttribute('data-document-language', true);
      sc.setAttribute('data-domain-script', oneTrustDomainId.value);
      head.appendChild(sc);
      oneTrustLoaded = true;
      return true;
    }
  } catch (ex) {
    console.log('one trust ex', ex);
    return false;
  }
};
export const getRegionName = (page) => {
  /**@type Array<SiteArea>*/
  const [areas, defaultRegionName] = getGlobalConfigs(page, 'areas', 'defaultRegionName');
  const pageAlpha2Code = getPageAlpha2Code(page);
  if (!areas) return;
  for (let a of areas) {
    for (let r of a.fields.regions) {
      if (r.fields.languages.some((lang) => equalString(getAlpha2CodeByLang(lang.fields.code.value), pageAlpha2Code))) {
        return r.displayName;
      }
    }
  }
  return defaultRegionName?.value || 'Global';
};
export const isGlobalSite = () => {
  if (!canUseDOM()) return false;
  const lang = getLangFromUrl();
  return isNullOrWhitespace(lang) || /^en$/gi.test(lang);
};
export const getCurrentAlpha2Code = () => {
  if (isGlobalSite()) return null;
  const lang = getLangFromUrl();
  return getAlpha2CodeByLang(lang);
};
export const getPageAlpha2Code = (page) => {
  if (isGlobalSite()) return null;
  const { itemLanguage } = page;
  return getAlpha2CodeByLang(itemLanguage);
};
export const getPageLanguage = (page) => {
  if (isGlobalSite()) return 'en';
  const { itemLanguage } = page;
  const [language] = itemLanguage.split('-');
  return language.toLowerCase();
};
export const getPageRegion = (page) => {
  const pageAlpha2Code = getPageAlpha2Code(page);
  const [areas] = getGlobalConfigs(page, 'areas');
  if (!areas?.length) return null;
  for (let area of areas) {
    for (let region of area.fields.regions) {
      const { alpha2Code, sfLang } = region.fields || {};
      if (equalString(alpha2Code.value, pageAlpha2Code)) {
        return {
          name: region.name,
          displayName: region.displayName,
          alpha2Code: pageAlpha2Code,
          sfLang: sfLang?.value
        };
      }
    }
  }
  return null;
};
export const getAlpha2CodeByLang = (lang) => {
  if (!isString(lang) || lang.indexOf('-') <= 0) return null;
  const [, code] = lang.split('-');
  return code.toUpperCase();
};
export const checkLangOfUrl = () => {
  if (!canUseDOM()) return;
  const { origin, pathname, search, hash } = window.location;
  const reg = new RegExp(`^/(en|[a-z]{2}-[A-Z]{2})(/|$)`, 'gi');
  if (!reg.test(pathname)) {
    window.location.replace(`${origin}/en${pathname === '/' ? '' : pathname}${search}${hash}`);
  }
  // const matches = pathname.match(/^\/(en|[a-z]{2}-[A-Z]{2})\/$/gi);
  // if (matches && matches.length > 0) {
  //   window.location.replace(`${origin}${pathname.substring(0, pathname.length - 1)}${search}${hash}`);
  // }
};
export const scrollToTop = () => {
  window.scrollTo(0, 0);
};
export const scrollElToTop = (el) => {
  el?.scrollIntoView();
};
export const scrollToEl = (el, position = 'top', ignoreHeader = false) => {
  if (!canUseDOM() || isNullOrEmpty(el)) return;
  const scrollTop = getScrollTop();
  const rect = el.getBoundingClientRect();
  const $header = global.document.querySelector('.c-header');
  let extraHeight = 0;
  if ($header.classList.contains('theme-transparent') && !$header?.classList.contains('hide')) {
    extraHeight = $header?.scrollHeight;
  }
  let headerHeight = $header?.classList.contains('hide') ? 0 : $header?.scrollHeight ?? 0;
  if (ignoreHeader) {
    headerHeight = 0;
    $header.classList.add('hide');
  }
  global.scrollTo({
    top: scrollTop + rect[position] - headerHeight + extraHeight,
    behavior: 'smooth'
  });
};
export const xml2Js = (xml, config) => {
  const x2js = require('x2js');
  const x2jsOne = new x2js(config);
  return x2jsOne.xml2js(xml);
};
export const loadYoutubeApi = async () => {
  try {
    const loaded = await injectScript('https://www.youtube.com/iframe_api', !!window.YT);
    return [loaded ? window.YT : null, null];
  } catch (ex) {
    return [null, ex];
  }
};
export const getRandomId = () => `${randomString(10, 2)}${new Date().getTime()}`;
export const getCountries = (page) => {
  let _countries = [];
  const [areas] = getGlobalConfigs(page, 'areas');
  if (!areas?.length) return [];
  for (let area of areas) {
    for (let region of area.fields.regions) {
      if (!region.fields.languages) continue;
      const _country = {
        code: region.name,
        text: region.displayName,
        data: {
          languages: region.fields.languages.map((y) => ({
            code: y.fields.code.value,
            text: y.fields.text.value,
            link: y.fields.link.value
          }))
        }
      };
      _countries.push(_country);
    }
  }
  _countries.sort((a, b) => (b.code > a.code ? -1 : 1));
  return _countries;
};
export const getAdaptiveTextField = (deviceState, desktopField, mobileField, tabletField, desktopLargeField) => {
  return getAdaptiveValueField(deviceState, desktopField, mobileField, tabletField, desktopLargeField, (x) => x?.value);
};
export const getAdaptiveImageField = (deviceState, desktopField, mobileField, tabletField, desktopLargeField) => {
  return getAdaptiveValueField(deviceState, desktopField, mobileField, tabletField, desktopLargeField, (x) => x?.value?.src);
};
export const getAdaptiveValueField = (deviceState, desktopField, mobileField, tabletField, desktopLargeField, valueFn) => {
  switch (deviceState.deviceType) {
    case 'mobile':
      return ifEmptyField([mobileField, tabletField, desktopField, desktopLargeField], valueFn);
    case 'tablet':
      return ifEmptyField([tabletField, mobileField, desktopField, desktopLargeField], valueFn);
    case 'tablet-landscape':
    case 'desktop':
    default:
      return ifEmptyField([desktopField, tabletField, mobileField, desktopLargeField], valueFn);
    case 'desktop-large':
      return ifEmptyField([desktopLargeField, desktopField, tabletField, mobileField], valueFn);
  }
};
export const getAdaptiveField = (deviceState, desktopField, mobileField, tabletField, desktopLargeField) => {
  switch (deviceState.deviceType) {
    case 'mobile':
      return mobileField ?? desktopField;
    case 'tablet':
      return tabletField ?? mobileField ?? desktopField;
    case 'tablet-landscape':
    case 'desktop':
      return desktopField;
    case 'desktop-large':
      return desktopLargeField ?? desktopField;
    default:
      return desktopField;
  }
};
export const tryField = (fields, valueFn) => {
  if (!isFn(valueFn) || !isArray(fields)) return null;
  for (let field of fields) {
    if (!isNullOrEmpty(valueFn(field))) return field;
  }
  return null;
};
export const tryValueField = (...fields) => {
  return tryField(fields, (x) => x?.value);
};
export const isEmptyFields = (fields, valueFn = (x) => x.value) => {
  if (!isFn(valueFn)) valueFn = (x) => x.value;
  return fields.every((x) => isNullOrEmpty(valueFn(x)));
};
export const encodeData = (data) => {
  return lzString.compressToEncodedURIComponent(JSON.stringify(data));
};
export const decodeData = (data) => {
  if (isNullOrWhitespace(data)) return null;
  const str = lzString.decompressFromEncodedURIComponent(data);
  try {
    return JSON.parse(str);
  } catch {
    return str;
  }
};
export const loadGRecaptcha = async (key, lang) => {
  await injectScript(`https://www.google.com/recaptcha/enterprise.js?render=${key}&hl=${lang}`, () => window.grecaptcha);
  return window.grecaptcha;
};
export const initializeGeeTest = (page, captchaId, captchaProduct = 'bind', container = null, _options = null, onSuccess) => {
  return new Promise((resolve) => {
    if (!canUseDOM()) {
      resolve(null);
      return;
    }
    const { origin } = global.location;
    const [libUrl] = getGlobalCfgValues(page, ['gtUrl'], ['https://static.geetest.com/v4/gt4.js']);
    injectScript(libUrl, () => false).then(() => {
      const options = merge(
        {
          captchaId: captchaId,
          language: page.itemLanguage,
          product: captchaProduct
        },
        _options
      );
      global.initGeetest4(options, (captcha) => {
        if (captchaProduct !== 'bind') {
          captcha.appendTo(container);
        }
        captcha.onSuccess(function() {
          const result = captcha.getValidate();
          result.captcha_id = captchaId;
          onSuccess && onSuccess(result);
          captcha.reset();
        });
        resolve(captcha);
      });
    });
  });
};
// export const validateGeeTest = (captcha, captchaId) => {
//   return new Promise((resolve) => {
//     captcha.onSuccess(function() {
//       const result = captcha.getValidate();
//       result.captcha_id = captchaId;
//       resolve(result);
//       captcha.reset();
//     });
//     try {
//       captcha.showCaptcha();
//     } catch (ex) {
//       console.log(ex);
//     }
//   });
// };
export const getGeeTestCaptchaIds = (page) => {
  const [geeTestCaptchaIds] = getGlobalConfigs(page, 'geeTestCaptchaIds');
  if (isNullOrEmpty(geeTestCaptchaIds.value)) return {};
  try {
    return querystring.parse(geeTestCaptchaIds.value);
  } catch {
    return {};
  }
};
export const getGeeTestCaptchaId = (page, name) => {
  const geeTestCaptchaIds = getGeeTestCaptchaIds(page);
  return geeTestCaptchaIds[name] ?? null;
};
export const loadAccessibe = async (page) => {
  const [accessibeLib] = getGlobalConfigs(page, 'accessibeLib');
  const accessibeUrl = accessibeLib?.value;
  if (!isNullOrEmpty(accessibeUrl)) {
    const lotusAccb = await loadRemoteModule(accessibeUrl, 'lotusAccb');
    lotusAccb?.code?.mount();
  }
  return null;
};
export const isAppBrowser = () => {
  if (!canUseDOM()) return false;
  const AndroidCmd = global.android?.callNative;
  const iosCmd = global.webkit?.messageHandlers?.callNative;
  return !!(AndroidCmd || iosCmd);
};
export const isOnApp = ($route) => {
  return equalString($route.query?.referrer, 'app') || equalString($route.query?.channel, 'app') || isAppBrowser();
};
export const appVisible = ($route, fields) => {
  if (!fields?.hideOnApp?.value) return true;
  return !isOnApp($route);
};

export const formatCarPrice = (routeData, priceData) => {
  if (isNullOrEmpty(routeData) || isNullOrEmpty(priceData)) return routeData;
  const keys = Object.keys(routeData);
  for (let key of keys) {
    const item = routeData[key];
    const type = getType(item);
    if (type === '[object String]') {
      routeData[key] = formatString(item, priceData);
    } else if (type === '[object Object]') {
      routeData[key] = formatCarPrice(item, priceData);
    } else if (type === '[object Array]') {
      for (let subItem of item) {
        formatCarPrice(subItem, priceData);
      }
    }
  }
  return routeData;
};
export const loadAcsb = async (page) => {
  const [accessibeLib] = getGlobalConfigs(page, 'accessibeLib');
  const accessibeUrl = accessibeLib?.value;
  if (isNullOrEmpty(accessibeUrl)) return;
  let locale = document.documentElement.lang;
  let localeparts = locale.split('-');
  let language = localeparts[0];
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'childList' && mutation.addedNodes.length > 0 && mutation.addedNodes[0].tagName === 'ACCESS-WIDGET-UI') {
        setTimeout(() => {
          let acsbWigets = document.querySelectorAll('access-widget-ui[data-acsb="widget"]');
          let acsbs = document.querySelectorAll('access-widget-ui[data-acsb=""]');
          if (acsbWigets.length > 0) {
            const acsb = acsbWigets[0];
            if (acsb) {
              const style = document.createElement('style');
              acsb.shadowRoot.appendChild(style);
              style.sheet.insertRule('.language-selector .flag, .language-selection__content .language__flag {display:none !important;}');
              style.sheet.insertRule('.language-selection__content button.language {justify-content: flex-start;}');
              style.sheet.insertRule('.language-selection__content button.language:nth-of-type(8) {display: none !important;}');
              style.sheet.insertRule('.language-selection__content button.language:nth-of-type(11) {display: none !important;}');
            }
          }
          if (acsbs.length > 0) {
            const acsb = acsbs[0];
            if (acsb) {
              const style = document.createElement('style');
              acsb.shadowRoot.appendChild(style);
              style.sheet.insertRule('::part(acsb-trigger) {z-index: 10 !important;}');
            }
          }
        }, 100);
      }
    });
  });
  observer.observe(document.body, {
    childList: true
  });
  await injectScript(accessibeUrl, () => !!global.acsbJS);
  global.acsbJS?.init({
    statementLink: '',
    feedbackLink: '',
    footerHtml: '',
    hideMobile: false,
    hideTrigger: false,
    language: language,
    position: 'left',
    leadColor: '#000',
    triggerColor: '#fff200',
    triggerRadius: '0',
    triggerPositionX: 'left',
    triggerPositionY: 'bottom',
    triggerIcon: 'default',
    triggerSize: 'small',
    triggerOffsetX: 22,
    triggerOffsetY: 92,
    mobile: {
      triggerSize: 'small',
      triggerPositionX: 'left',
      triggerPositionY: 'bottom',
      triggerOffsetX: 16,
      triggerOffsetY: 16,
      triggerRadius: '0'
    }
  });
};
export const formatModel = (model, caseType = 'uppercase') => {
  let result = model?.replace(/(\s+(base|i4))/gi, '');
  const caseFn = {
    uppercase(str) {
      return str?.toUpperCase();
    },
    capitalize(str) {
      return toCapitalize(str);
    }
  };
  if (!caseFn[caseType]) return result;
  return caseFn[caseType](result);
};
export const formDdl = (field) => {
  if (!field?.fields) return null;
  const { title, label, placeholder, description, options, clearable, clearText, hideSelected } = field.fields;
  return {
    title: title.value,
    label: label.value,
    placeholder: placeholder.value,
    description: description.value,
    clearable: clearable.value,
    clearText: clearText?.value,
    hideSelected: hideSelected.value,
    options: options.map((x) => ({
      code: x.fields.code.value,
      text: x.fields.text.value
    }))
  };
};
export const destroySwiper = (swiper, ...options) => {
  try {
    swiper?.destroy(...options);
  } catch (ex) {
    console.log(ex);
  }
};
export const formatDistance = (page, distance, unit = 'km', fractionalNum = 2) => {
  const [distanceUnit] = getGlobalConfigs(page, 'distanceUnit');
  const { phrase, text } = distanceUnit?.fields || {};
  const { value: targetUnit = 'km' } = phrase || {};
  const { value: unitLabel = 'Km' } = text || {};
  let dis = distance;
  if (isNaN(dis)) return dis;
  if (equalString(unit, 'm')) dis = distance / 1000;
  if (equalString(targetUnit, 'miles')) dis = dis * 0.6214;
  let formatter = new Intl.NumberFormat(page.itemLanguage, { minimumFractionDigits: fractionalNum, maximumFractionDigits: fractionalNum });
  return `${formatter.format(dis)} ${unitLabel}`;
};
export const buildShopCheckoutUrl = (page, goodsList, cartOrder = false) => {
  const [shopCheckoutLifeLink] = getGlobalConfigs(page, 'shopCheckoutLifeLink');
  let checkoutUrl = getBetterUrl(ifEmpty(shopCheckoutLifeLink?.value.href, '/webshop/checkout-life'), null, true, false);
  if (!isArray(goodsList) || goodsList.length === 0) return null;
  const order_details = encodeData({
    cartOrder,
    orderItems: goodsList.map((x) => ({
      skuId: x.skuId,
      num: x.quantity
    }))
  });
  checkoutUrl = `${checkoutUrl}?order_details=${order_details}`;
  return checkoutUrl;
};
export const getPriceFromPcData = (priceInfo, name, ...fallbackNames) => {
  return Number(priceInfo?.find((x) => equalString(x.elementName, name) || fallbackNames.some((y) => equalString(x.elementName, y)))?.price);
};
export const findCwBlockDetail = (blocks, key) => {
  if (!isArray(blocks)) return null;
  for (let m of blocks) {
    if (!isArray(m?.details)) continue;
    for (let n of m.details) {
      if (equalString(n.key, key) || equalString(n.name, key)) return n;
    }
  }
  return null;
};
export const isPrdEnv = (href = null) => {
  if (!canUseDOM()) return false;
  const reg = /https:\/\/www.lotuscars.com/gi;
  if (href) return reg.test(href);
  else if (canUseDOM()) return reg.test(global.location.href);
  return false;
};
export const formData2Body = (formData) => {
  if (isNullOrEmpty(formData)) return null;
  const result = Object.create(null);
  const keys = Object.keys(formData);
  for (let key of keys) {
    const data = formData[key];
    if (key === 'externalData') continue;
    if (key === 'birthday') {
      result[key] = formatDate(data);
    } else if (utils.obj.hasProperty(data, 'area')) {
      result[key] = concatString(['+', data.area?.code, data.number], '');
    } else if (utils.obj.hasProperty(data, 'code')) {
      result[key] = data.code;
    } else if (isArray(data)) {
      result[key] = data.map((x) => x.code).join(',');
    } else {
      result[key] = data;
    }
  }
  if (!isNullOrEmpty(formData.externalData)) {
    merge(result, formData.externalData);
  }
  return result;
};
export const getFormDefaultValues = (form) => {
  const { items } = form.fields || {};
  const result = Object.create(null);
  if (!isArray(items) || items.length === 0) return result;
  for (let item of items) {
    const { dataName, fieldType, defaultValue, defaultAreaCode, defaultPhoneNumber, checked, selectedOption } = unWrapSitecoreItem(item.fields || {});
    if (isNullOrEmpty(dataName)) continue;
    switch (fieldType?.phrase) {
      case 'form-input':
      case 'form-pick-up':
        result[dataName] = defaultValue;
        break;
      case 'phone-input':
        result[dataName] = {
          area: {
            code: defaultAreaCode
          },
          number: defaultPhoneNumber
        };
        break;
      case 'checkbox':
        result[dataName] = checked;
        break;
      case 'dropdown':
        result[dataName] = selectedOption?.code;
        break;
      default:
        break;
    }
  }
  console.log('default form value', result);
  return result;
};

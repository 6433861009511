export default `
<svg width="132" height="131" viewBox="0 0 132 131" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_b_908_8977)">
<circle cx="66.2383" cy="65.4999" r="65.5" fill="black" fill-opacity="0.2"/>
</g>
<g filter="url(#filter1_d_908_8977)">
<path d="M52.7383 35.9999V99.9999" stroke="currentcolor" stroke-width="2"/>
<path d="M80.7383 35.9999V99.9999" stroke="currentcolor" stroke-width="2"/>
</g>
<defs>
<filter id="filter0_b_908_8977" x="-14.2617" y="-15.0001" width="161" height="161" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_908_8977"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_908_8977" result="shape"/>
</filter>
<filter id="filter1_d_908_8977" x="31.7383" y="15.9999" width="70" height="104" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="10"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.8 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_908_8977"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_908_8977" result="shape"/>
</filter>
</defs>
</svg>
`;

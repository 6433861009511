import { getType, isArray } from '@/utils/obj-utils';

export const arraySum = (array, selector) => {
  if (!isArray(array)) return 0;
  return array.reduce((prev, current) => prev + selector(current) ?? 0, 0);
};
export const arraySort = (array, element) => {
  return array.sort((a, b) => b[element] - a[element]);
};
export const sortByChar = (array, selector = null, desc = false) => {
  if (!array?.length) return array;
  array.sort((a, b) => {
    let av = (selector ? selector(a) : a) ?? '';
    let bv = (selector ? selector(b) : b) ?? '';
    const direction = desc ? -1 : 1;
    const type = getType(av);
    let result = 0;
    if (type === '[object Number]') {
      result = ((av - bv) / Math.abs(av - bv)) * direction;
    } else if (type === '[object String]') {
      av = av.toLowerCase();
      bv = bv.toLowerCase();
      const len = Math.max(av.length, bv.length);
      for (let i = 0; i < len; i++) {
        if (i > av.length - 1) return direction;
        if (i > bv.length - 1) return -direction;
        const aCode = av.charCodeAt(i),
          bCode = bv.charCodeAt(i);
        if (aCode !== bCode) {
          result = ((aCode - bCode) / Math.abs(aCode - bCode)) * direction;
          break;
        }
      }
    }
    return result;
  });
  return array;
};
export const isSubset = (arr1, arr2) => {
  return arr1.length <= arr2.length && arr1.every((value) => arr2.includes(value));
};
export const hasIntersection = (arr1, arr2) => {
  return arr1.some((i) => arr2.includes(i));
};
export const unionBy = (set1, set2, compareFn, mergeFn) => {
  const result = isArray(set1) ? [...set1] : [];
  const mergeToResult = (a) => {
    const rIndex = result.findIndex((x) => compareFn(x, a));
    if (rIndex >= 0) {
      result[rIndex] = mergeFn(result[rIndex], a);
    } else {
      result.push(a);
    }
  };
  if (isArray(set2)) {
    for (let y of set2) {
      mergeToResult(y);
    }
  }
  return result;
};

import { decimalToHex, hexToDecimal } from '@/utils/math-utils';

/**@typedef MixColorItem
 * @property {String} color
 * @property {Number} weight
 * */
/**
 * @param {MixColorItem} colorSet
 * @returns {String} color
 * */
export const mixColor = (...colorSet) => {
  let result = '#';
  for (let i = 1; i <= 6; i += 2) {
    let colorNumber = colorSet.reduce((prev, current) => {
      prev += Math.round(hexToDecimal(current.color.substr(i, 2)) * current.weight);
      return prev;
    }, 0);
    if (colorNumber > 255) colorNumber = 255;
    let colorPart = decimalToHex(colorNumber);
    if (colorPart.length < 2) {
      colorPart = '0' + colorPart;
    }
    result += colorPart;
  }
  return result;
};
export const diffColor = (color1, color2) => {
  let result = '#';
  for (let i = 1; i <= 6; i += 2) {
    let colorNumber = Math.abs(hexToDecimal(color1.substr(i, 2)) - hexToDecimal(color2.substr(i, 2)));
    let colorPart = decimalToHex(colorNumber);
    if (colorPart.length < 2) {
      colorPart = '0' + colorPart;
    }
    result += colorPart;
  }
  return result;
};

export const isSimilarColor = (color1, color2, difference = 25) => {
  let result = [];
  for (let i = 1; i <= 6; i += 2) {
    let colorNumber = Math.abs(hexToDecimal(color1.substr(i, 2)) - hexToDecimal(color2.substr(i, 2)));
    result.push(colorNumber);
  }
  return result.every((x) => x < difference);
};
export const isGrey = (color) => {
  return isSimilarColor(color, '#888888', 50);
};
export const reverseColor = (color) => {
  let result = '#';
  for (let i = 1; i <= 6; i += 2) {
    let colorNumber = Math.abs(255 - hexToDecimal(color.substr(i, 2)));
    let val = decimalToHex(colorNumber);
    if (val.length === 1) {
      val = '0' + val;
    }
    result += val;
  }
  return result;
};

import { camelCase, merge } from 'lodash';
import { formatString, ifEmpty } from '@/utils/string-utils';
import { fm2 } from '@/utils/math-utils';
export const getType = (object) => {
  return Object.prototype.toString.call(object);
};
export const isNull = (object) => {
  return object === null || object === undefined;
};
export const hasProperty = (object, key) => {
  if (isNullOrEmpty(object)) return false;
  return Object.prototype.hasOwnProperty.call(object, key);
};
export const hasProperties = (object, keys) => {
  for (let key of keys) {
    if (!hasProperty(object, key)) return false;
  }
  return true;
};
export const isNullOrEmpty = (object) => {
  if (isNull(object) || object === '') {
    return true;
  }
  const type = getType(object);
  if (type === '[object Array]' && object.length === 0) {
    return true;
  }
  if (type === '[object Object]' && Object.keys(object).length === 0) {
    return true;
  }
  return type === '[object Set]' && object.size === 0;
};
export const isNullOrWhitespace = (object) => {
  return isNullOrEmpty(object) || /^\s*$/gi.test(object);
};
export const isString = (object) => {
  return getType(object) === '[object String]';
};
export const isArray = (object) => {
  return getType(object) === '[object Array]';
};
export const isNumber = (object) => {
  return getType(object) === '[object Number]';
};
export const isBool = (object) => {
  return getType(object) === '[object Boolean]';
};
export const isFn = (object) => {
  return getType(object) === '[object Function]';
};
export const isObject = (object) => {
  return getType(object) === '[object Object]';
};
export const isIterable = (object) => {
  return !isNullOrEmpty(object) && isFn(object[Symbol.iterator]);
};
export const padding = (object, fillLength = 2, char = '0', isEnd = false) => {
  if (isNull(object)) return null;
  const str = object.toString();
  const len = str.length;
  if (len >= fillLength) return str;
  const paddingValue = Math.pow(10, fillLength - len)
    .toString()
    .substr(1)
    .replace(/0/gi, char);
  const result = [paddingValue, str];
  if (isEnd) {
    result.reverse();
  }
  return result.join('');
};
export const ifNullOrEmpty = (value1, value2) => {
  return isNullOrEmpty(value1) ? value2 : value1;
};
export const deepCopy = (dataSource) => {
  let copyData = Array.isArray(dataSource) ? [] : Object.create(null);
  if (dataSource && typeof dataSource === 'object') {
    for (let key in dataSource) {
      if (Object.prototype.hasOwnProperty.call(dataSource, key)) {
        if (dataSource[key] && typeof dataSource[key] === 'object') {
          copyData[key] = deepCopy(dataSource[key]);
        } else {
          copyData[key] = dataSource[key];
        }
      }
    }
  } else {
    copyData = dataSource;
  }
  return copyData;
};
export const toCamelObject = (obj) => {
  const type = getType(obj);
  if (obj === null || type !== '[object Object]') return null;
  const result = Object.create(null);
  const keys = Object.keys(obj);
  for (let key of keys) {
    const child = obj[key];
    const childType = getType(child);
    const childKey = camelCase(key);
    if (childType === '[object Object]') {
      result[childKey] = toCamelObject(child);
    } else if (childType === '[object Array]') {
      let childArr = [];
      for (let grandson of child) {
        childArr.push(toCamelObject(grandson));
      }
      result[childKey] = childArr;
    } else {
      result[childKey] = child;
    }
  }
  return result;
};
export const getPossibleData = (key, ...objects) => {
  let result = null;
  for (let obj of objects) {
    if (getType(obj) !== '[object Object]' || isNullOrEmpty(obj[key])) continue;
    result = obj[key];
  }
  return result;
};
export const appendValueIfNotNull = (obj, key, val) => {
  if (!isNullOrEmpty(val)) {
    merge(obj, {
      [key]: val
    });
  }
};
export const appendMoneyIfNotNull = (obj, key, val, currency) => {
  if (!isNullOrEmpty(val)) {
    merge(obj, {
      [key]: fm2(val, currency)
    });
  }
};
export const appendValWithTpl = (obj, key, val, tpl) => {
  if (!isNullOrEmpty(val)) {
    merge(obj, {
      [key]: formatString(tpl, { val })
    });
  }
};

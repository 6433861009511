import { defineStore } from 'pinia';
import { computed, reactive, toRefs } from 'vue';
import { webStorage } from '@/utils/web-storage';
import {
  S_LOGIN_INFO,
  S_USER_INFO,
  S_ORDER_LIST,
  S_MALL_ORDER_LIST,
  S_PARTS_ORDER_LIST,
  S_SERVICE_ORDER_LIST,
  S_GEO_LOCATION,
  S_CART_INFO,
  S_SERVER_CART_INFO,
  S_HIDE_MALL_ORDER,
  S_HIDE_VEHICLE_ORDER,
  S_HIDE_WHEELS_ORDER,
  S_HIDE_SERVICE_ORDER,
  S_AFTER_SALES_INSTALL
} from '@/utils/web-storage-keys';
import { canUseDOM } from '@/utils/dom-utils';
import { differenceBy, intersectionBy, merge } from 'lodash';
import { qtUtils } from '@/utils/ali-tracker-utils';
import { isArray, isNullOrEmpty } from '@/utils/obj-utils';
import { arraySum, unionBy } from '@/utils/array-utils';
import { equalString, ifEmpty } from '@/utils/string-utils';
import services from '@/services';
import { APP_CONST } from '@/utils/constants';
import api from '@/api';
import { getCurrentAlpha2Code } from '@/utils/site-utils';
import { ifNaN, round } from '@/utils/math-utils';
import { checkDisableStatus } from '@/services/mallService';
let useAppStore = () => Object.create(null);
if (canUseDOM()) {
  /**@type LoginInfo*/
  const loginInfo = webStorage.get(S_LOGIN_INFO);
  /**@type OrderInfo*/
  const orderInfo = webStorage.get(S_ORDER_LIST);
  const shopOrderInfo = webStorage.get(S_MALL_ORDER_LIST);
  const partsOrderInfo = webStorage.get(S_PARTS_ORDER_LIST);
  const serviceOrderInfo = webStorage.get(S_SERVICE_ORDER_LIST);
  const hideLifeOrder = webStorage.get(S_HIDE_MALL_ORDER);
  const hideVehicleOrder = webStorage.get(S_HIDE_VEHICLE_ORDER);
  const hideWheelsOrder = webStorage.get(S_HIDE_WHEELS_ORDER);
  const hideServiceOrder = webStorage.get(S_HIDE_SERVICE_ORDER);
  const afterSalesInstall = webStorage.get(S_AFTER_SALES_INSTALL);
  // S_MALL_ORDER_TOTAL, S_VEHICLE_ORDER_TOTAL
  // const
  /**@type MyGeolocation*/
  const geoLocation = webStorage.get(S_GEO_LOCATION);
  /**@type Array<CartItem>*/
  let cartInfo = webStorage.get(S_CART_INFO);
  if (!isArray(cartInfo)) {
    cartInfo = [];
  }
  /**
   * @return AppStore
   */
  useAppStore = defineStore('app', () => {
    const state = reactive({
      loginInfo,
      orderInfo,
      shopOrderInfo,
      partsOrderInfo,
      serviceOrderInfo,
      hideLifeOrder,
      hideVehicleOrder,
      hideWheelsOrder,
      hideServiceOrder,
      geoLocation,
      cartInfo,
      cartCurrency: '',
      afterSalesInstall: false
      // userInfo: webStorage.get(S_USER_INFO)
    });
    const computes = {
      nickName: computed(() => state?.loginInfo?.nickName),
      hasLoggedIn: computed(() => !!state?.loginInfo?.lotusId),
      hasGeoLocation: computed(() => !isNullOrEmpty(state?.geoLocation?.longitude) && !isNullOrEmpty(state?.geoLocation?.latitude)),
      cartTotalQuantity: computed(() =>
        arraySum(
          state.cartInfo?.filter((x) => equalString(x.market, APP_CONST.market.code) && x.checked),
          (x) => ifNaN(x.quantity, 0)
        )
      ),
      cartTotalAmount: computed(() =>
        state.cartInfo.reduce((prev, current) => {
          if (equalString(current.market, APP_CONST.market.code) && current.checked) {
            return round(prev + (current.checked && !isNullOrEmpty(current.detail.price) ? current.detail.price * ifNaN(current.quantity, 0) : 0), 2);
          }
          return prev;
        }, 0)
      ),
      cartTotalWeight: computed(() =>
        state.cartInfo.reduce((prev, current) => {
          if (equalString(current.market, APP_CONST.market.code) && current.checked) {
            return round(prev + (current.checked ? ifNaN(current.detail.weight, 0) * ifNaN(current.quantity, 0) : 0), 2);
          }
          return prev;
        }, 0)
      )
    };
    const _methods = {};
    const methods = {
      async setLoginInfo(payload) {
        webStorage.set(S_LOGIN_INFO, payload);
        state.loginInfo = payload;
        qtUtils.setMetaInfo('_user_id', payload?.lotusId ?? null);
        if (computes.hasLoggedIn.value) {
          await methods.mergeCartToServer().catch();
        }
      },
      updateLoginInfo(payload) {
        const oldInfo = webStorage.get(S_LOGIN_INFO);
        const newInfo = Object.create(null);
        merge(newInfo, oldInfo, payload);
        webStorage.set(S_LOGIN_INFO, newInfo);
        state.loginInfo = newInfo;
        qtUtils.setMetaInfo('_user_id', newInfo?.lotusId ?? null);
      },
      setUserInfo(payload) {
        webStorage.set(S_USER_INFO, payload);
        state.userInfo = payload;
      },
      UpdateUserInfo(payload) {
        const newInfo = Object.create(null);
        merge(newInfo, state.userInfo, payload);
        webStorage.set(S_USER_INFO, newInfo);
        state.userInfo = newInfo;
      },
      setOrderInfo(payload) {
        webStorage.set(S_ORDER_LIST, payload);
        state.orderInfo = payload;
      },
      UpdateOrderInfo(payload) {
        const newInfo = Object.create(null);
        merge(newInfo, state.orderInfo, payload);
        webStorage.set(S_ORDER_LIST, newInfo);
        state.orderInfo = newInfo;
      },
      setShopOrderInfo(payload) {
        webStorage.set(S_MALL_ORDER_LIST, payload);
        state.shopOrderInfo = payload;
      },
      updateShopOrderInfo(payload) {
        const newInfo = Object.create(null);
        merge(newInfo, state.shopOrderInfo, payload);
        webStorage.set(S_MALL_ORDER_LIST, newInfo);
        state.shopOrderInfo = newInfo;
      },
      setPartsOrderInfo(payload) {
        webStorage.set(S_PARTS_ORDER_LIST, payload);
        state.partsOrderInfo = payload;
      },
      updatePartsOrderInfo(payload) {
        const newInfo = Object.create(null);
        merge(newInfo, state.partsOrderInfo, payload);
        webStorage.set(S_PARTS_ORDER_LIST, newInfo);
        state.partsOrderInfo = newInfo;
      },
      setServiceOrderInfo(payload) {
        webStorage.set(S_SERVICE_ORDER_LIST, payload);
        state.serviceOrderInfo = payload;
      },
      updateServiceOrderInfo(payload) {
        const newInfo = Object.create(null);
        merge(newInfo, state.serviceOrderInfo, payload);
        webStorage.set(S_SERVICE_ORDER_LIST, newInfo);
        state.serviceOrderInfo = newInfo;
      },
      setHideLifeOrder(payload) {
        webStorage.set(S_HIDE_MALL_ORDER, payload);
        state.hideLifeOrder = payload;
      },
      setHideVehicleOrder(payload) {
        webStorage.set(S_HIDE_VEHICLE_ORDER, payload);
        state.hideVehicleOrder = payload;
      },
      setHideWheelsOrder(payload) {
        webStorage.set(S_HIDE_WHEELS_ORDER, payload);
        state.hideWheelsOrder = payload;
      },
      setHideServiceOrder(payload) {
        webStorage.set(S_HIDE_SERVICE_ORDER, payload);
        state.hideServiceOrder = payload;
      },
      setAfterSalesInstall(payload) {
        webStorage.set(S_AFTER_SALES_INSTALL, payload);
        state.afterSalesInstall = payload;
      },
      setGeoLocation(payload) {
        webStorage.set(S_GEO_LOCATION, payload);
        state.geoLocation = payload;
      },
      updateGeoLocation(payload) {
        const newPayload = Object.create(null);
        merge(newPayload, state.geoLocation, payload);
        webStorage.set(S_MALL_ORDER_LIST, newPayload);
        state.shopOrderInfo = newPayload;
        state.partsOrderInfo = newPayload;
      },
      setCartInfo(payload) {
        if (!isArray(payload)) return;
        state.cartInfo = payload;
        webStorage.set(S_CART_INFO, payload);
      },
      async addToCart(market, skuId, quantity) {
        const cartItem = methods.getCartItem(market, skuId);
        if (computes.hasLoggedIn.value) {
          const [, ex] = await api.shop.cart.add(null, {
            cartProductList: [
              {
                skuId,
                quantity
              }
            ],
            market
          });
          state.cartInfo = await services.mall.getServerCartInfo(true);
          webStorage.set(S_CART_INFO, state.cartInfo);
          return [state.cartInfo, ex];
        } else {
          if (cartItem) cartItem.quantity += quantity;
          else {
            state.cartInfo.push({ market, skuId, quantity, checked: true });
          }
          await methods.loadCartDetail();
          webStorage.set(S_CART_INFO, state.cartInfo);
          return [state.cartInfo, null];
        }
      },
      async updateToCart(market, skuId, quantity) {
        if (computes.hasLoggedIn.value) {
          const [, ex] = await api.shop.cart.update(null, {
            cartProductList: [
              {
                skuId,
                quantity
              }
            ],
            market
          });
          state.cartInfo = await services.mall.getServerCartInfo(true);
          webStorage.set(S_CART_INFO, state.cartInfo);
          return [state.cartInfo, ex];
        } else {
          const cartItem = methods.getCartItem(market, skuId);
          if (cartItem) cartItem.quantity = quantity;
          else {
            state.cartInfo.push({ market, skuId, quantity, checked: true });
          }
          await methods.loadCartDetail();
          webStorage.set(S_CART_INFO, state.cartInfo);
          return [state.cartInfo, null];
        }
      },
      async removeFromCart(market, skuId, quantity = 0) {
        const cartItemIndex = methods.getCartItemIndex(market, skuId);
        if (cartItemIndex < 0) return [null, null];
        const cartItem = state.cartInfo[cartItemIndex];
        const remainQuantity = cartItem.quantity - quantity;
        const willDelete = quantity === 0 || remainQuantity <= 0;
        if (computes.hasLoggedIn.value) {
          let ex;
          if (willDelete) {
            [, ex] = await api.shop.cart.delete(null, {
              skuIdList: [skuId],
              market
            });
          } else {
            [, ex] = await api.shop.cart.update(null, {
              cartProductList: [
                {
                  skuId,
                  quantity: remainQuantity
                }
              ],
              market
            });
          }
          state.cartInfo = await services.mall.getServerCartInfo(true);
          webStorage.set(S_CART_INFO, state.cartInfo);
          return [state.cartInfo, ex];
        } else {
          if (willDelete) {
            state.cartInfo.splice(cartItemIndex, 1);
          } else {
            cartItem.quantity = remainQuantity;
          }
          await methods.loadCartDetail();
          webStorage.set(S_CART_INFO, state.cartInfo);
          return [state.cartInfo, null];
        }
      },
      async clearCart() {
        const { market } = APP_CONST;
        if (computes.hasLoggedIn.value) {
          const skuIdList = state.cartInfo.filter((x) => equalString(x.market, market.code)).map((x) => x.skuId);
          if (skuIdList.length > 0) {
            const [, ex] = await api.shop.cart.delete(null, {
              skuIdList,
              market: market.code
            });
            state.cartInfo = await services.mall.getServerCartInfo(true);
            webStorage.set(S_CART_INFO, state.cartInfo);
            return [state.cartInfo, ex];
          }
          return [state.cartInfo, null];
        } else {
          state.cartInfo = state.cartInfo.filter((x) => !equalString(x.market, market.code));
          webStorage.set(S_CART_INFO, state.cartInfo);
          return [state.cartInfo, null];
        }
      },
      getCartItem(market, skuId) {
        return state.cartInfo.find((x) => equalString(x.market, market) && equalString(x.skuId, skuId));
      },
      getCartItemIndex(market, skuId) {
        return state.cartInfo.findIndex((x) => equalString(x.market, market) && equalString(x.skuId, skuId));
      },
      async mergeCartToServer() {
        const { market } = APP_CONST;
        if (computes.hasLoggedIn.value) {
          let cartInfoServer = await services.mall.getServerCartInfo();
          const cartInfoLocal = webStorage.get(S_CART_INFO)?.filter((x) => equalString(x.market, market.code));
          const list = unionBy(
            cartInfoServer,
            cartInfoLocal,
            (a, b) => {
              return a?.skuId === b?.skuId;
            },
            (a, b) => {
              return merge({}, a, b, {
                quantity: Math.max(a.quantity, b.quantity)
              });
            }
          ).map((x) => ({
            skuId: x.skuId,
            quantity: x.quantity
          }));
          const updateList = list.filter((x) => cartInfoServer?.some((y) => x.skuId === y.skuId));
          const addList = differenceBy(list, updateList, 'skuId');
          await Promise.all([
            api.shop.cart.update(null, {
              cartProductList: updateList,
              market: market.code
            }),
            api.shop.cart.add(null, {
              cartProductList: addList,
              market: market.code
            })
          ]);
          return await methods.loadCartInfo();
        }
      },
      async loadCartInfo() {
        if (computes.hasLoggedIn.value) {
          state.cartInfo = await services.mall.getServerCartInfo();
          if (state.cartInfo?.length > 0) {
            state.cartCurrency = state.cartInfo[0].detail.currency;
          }
          webStorage.set(S_CART_INFO, state.cartInfo);
        } else {
          await methods.loadCartDetail();
        }
        return state.cartInfo;
      },
      async loadCartDetail() {
        const alpha2Code = getCurrentAlpha2Code();
        const cartInfo = state.cartInfo;
        if (!cartInfo?.length) return [];
        const skuIds = cartInfo.map((x) => x.skuId).join(',');
        const [res] = await api.shop.cart.getDetails({
          ids: skuIds
        });
        if (res?.length > 0) {
          let currency = 'EUR';
          for (let item of cartInfo) {
            const skuDetail = res.find((x) => x.id === item.skuId);
            if (!skuDetail) continue;
            const { skuImageSeo, skuImage, spuMainImage } = skuDetail;
            const image = ifEmpty(skuImageSeo?.skuImages?.length > 0 ? skuImageSeo.skuImages[0]?.pic : null, skuImage, spuMainImage);
            const detail = {
              name: skuDetail.spuName,
              description: skuDetail.spuDescription,
              image,
              stock: skuDetail.sellStock,
              specs: skuDetail.specList.map((x) => ({
                name: x.propertyName,
                value: x.propertyValue
              })),
              status: skuDetail.status,
              spuStatus: skuDetail.spuStatus,
              weight: skuDetail.weight
            };
            if (skuDetail.priceInfoList?.length > 0) {
              const priceInfo = skuDetail.priceInfoList.find((x) => equalString(x.country, alpha2Code));
              if (!isNaN(priceInfo?.price)) {
                // totalAmount += priceInfo.price * item.quantity;
                currency = priceInfo.currency;
                merge(detail, {
                  price: priceInfo.price,
                  currency: priceInfo.currency
                });
              }
            }
            item.detail = detail;
            item.disabled = false;
            checkDisableStatus(item);
          }
          state.cartCurrency = currency;
          webStorage.set(S_CART_INFO, cartInfo);
        }
        return cartInfo;
      }
    };
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  });
}
export default useAppStore;

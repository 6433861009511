import { getType, isNullOrEmpty } from '@/utils/obj-utils';
import { getCurrentAlpha2Code } from '@/utils/site-utils';
import { equalString } from '@/utils/string-utils';

export const decimalToHex = (decimal) => {
  return decimal.toString(16);
};
export const hexToDecimal = (hex) => {
  return parseInt(hex, 16);
};
export const formatMoney = (money, currency = 'EUR', maximumFractionDigits = 0) => {
  const type = getType(money);
  if (type !== '[object Number]') money = Number(money);
  let locale = getCurrentAlpha2Code() ?? 'NL';
  if (equalString(locale, 'SE')) locale = 'sv-SE';
  if (equalString(locale, 'DK')) locale = 'da-DK';
  if (isNullOrEmpty(money) || isNaN(money) || isNullOrEmpty(currency)) return null;
  const formatter = new Intl.NumberFormat(locale, { style: 'currency', currency, maximumFractionDigits });
  return formatter.format(money).replace(/^[a-z]*\$[a-z]*/gi, '$');
};
export const fm2 = (money, currency = 'EUR') => formatMoney(money, currency, 2);
export const getCurrencySymbol = (currency = 'EUR') => {
  return formatMoney(0, currency).replace(/[0-9a-z.\s,]*/gi, '');
};
export const formatShopMoney = (money, currency = 'EUR', maximumFractionDigits = 2) => {
  return formatMoney(money / 100, currency, maximumFractionDigits);
};
export const toThousands = (number, maximumFractionDigits = 0, minimumFractionDigits = 0) => {
  const type = getType(number);
  if (type !== '[object Number]') number = Number(number);
  if (isNaN(number)) return '';
  let locale = getCurrentAlpha2Code() ?? 'nl-NL';
  const formatter = new Intl.NumberFormat(locale, { maximumFractionDigits, minimumFractionDigits });
  return formatter.format(number);
};
export const sum = (array, key = null) => {
  let result = 0;
  for (let item of array) {
    const num = key ? item[key] : item;
    if (!isNaN(num)) {
      result += Number(num);
    }
  }
  return result;
};
export const round = (number, decimalPlaces = 2) => {
  const type = getType(number);
  let val = number;
  if (type !== '[object Number]') val = Number(val);
  if (isNaN(number)) return number;
  const temp = Math.pow(10, decimalPlaces);
  return Math.round(val * temp) / temp;
};
export const ifNaN = (...args) => {
  for (let arg of args) {
    if (!isNaN(arg)) return arg;
  }
  return null;
};

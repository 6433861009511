import api from '@/api';
import { equalString } from '@/utils/string-utils';
import { getCurrentAlpha2Code, getPageAlpha2Code } from '@/utils/site-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';

export const getBenefits = async (page, benefitIds) => {
  const alpha2Code = getPageAlpha2Code(page);
  const resAll = await Promise.all(
    benefitIds.map((x) =>
      api.benefits.get(null, {
        id: x,
        language: page.itemLanguage
      })
    )
  );
  const benefitsList = [];
  let totalBenefitsAmount = 0;
  for (let res of resAll) {
    const [benefitsRes] = res;
    if (benefitsRes) {
      const { id, name, remark, rightsExplain, priceId, priceList, multilingualList } = benefitsRes;
      const multilingualListData = JSON.parse(multilingualList);
      const currentLangData = multilingualListData?.find((x) => equalString(x.dictValue?.split('-')[0], page.itemLanguage?.split('-')[0]));
      const priceItem = priceList?.find((x) => equalString(alpha2Code, x.country));
      let price = Number(priceItem?.price) ?? 0;
      if (isNaN(price)) {
        price = 0;
      }
      const currency = priceItem?.currency;
      benefitsList.push({
        id,
        code: name,
        name: currentLangData?.name ?? name,
        remark: currentLangData?.remark ?? remark,
        rightsExplain: currentLangData?.rightsExplain ?? rightsExplain,
        price,
        priceId,
        currency
      });
      totalBenefitsAmount += price;
    }
  }
  benefitsList.sort((a, b) => b.price - a.price);
  // let benefitsInfo = null;
  let benefitPriceIds = [];
  for (let bl of benefitsList) {
    if (!isNullOrEmpty(bl?.priceId)) {
      benefitPriceIds.push(bl?.priceId);
      // benefitsInfo = {
      //   priceId: bl?.priceId ?? '',
      //   rightsId: bl?.id ?? ''
      // };
    }
  }
  return [benefitsList, benefitPriceIds, totalBenefitsAmount];
};
export const getBenefitsFromPackage = (rightsPackageList = []) => {
  const alpha2Code = getCurrentAlpha2Code();
  const benefitsList = [];
  const benefitPriceIds = [];
  let totalBenefitsAmount = 0;
  if (rightsPackageList?.length > 0) {
    for (let rl of rightsPackageList) {
      if (!rl.optional || rl.selected) {
        let priceInfo = rl.priceList?.find((x) => equalString(x.country, alpha2Code));
        let price = Number(priceInfo?.price) ?? null;
        benefitsList.push({
          id: rl.id,
          code: rl.name,
          name: rl.name,
          remark: rl.remark,
          rightsExplain: rl.rightsExplain,
          price,
          priceId: rl.priceId ?? null,
          currency: priceInfo?.currency ?? null
        });
        if (price) {
          benefitPriceIds.push(rl.priceId);
          totalBenefitsAmount += price;
        }
      }
    }
  }
  console.log('benefitPriceIds', benefitPriceIds);
  return [benefitsList, benefitPriceIds, totalBenefitsAmount];
};

import { getType, hasProperty, isArray, isFn, isNullOrEmpty, isString } from '@/utils/obj-utils';
import { equalString, ifEmpty, toFistLetterUpper } from '@/utils/string-utils';
import querystring from 'querystring';
import utils from '@/utils/index';

export const unWrapSitecoreItem = (item) => {
  const itemData = item?.fields ?? item?.value ?? item;
  const itemType = getType(itemData);
  if (itemType === '[object Array]') {
    const arrResult = [];
    for (let child of itemData) {
      arrResult.push(unWrapSitecoreItem(child));
    }
    return arrResult;
  }
  if (itemType !== '[object Object]') {
    if (itemType === '[object String]' && /^[^=]+=[^=]+(&[^=]+=[^=]+)*$/gi.test(itemData)) {
      try {
        return querystring.parse(itemData);
      } catch {
        return itemData;
      }
    }
    return itemData;
  }
  const keys = Object.keys(itemData);
  const result = Object.create(null);
  for (let key of keys) {
    const child = itemData[key];
    result[key] = unWrapSitecoreItem(child);
  }
  return result;
};
export const buildConfigField = (phrase, text) => {
  return {
    fields: {
      phrase: {
        value: phrase
      },
      text: {
        value: ifEmpty(text, phrase)
      }
    }
  };
};
export const parseNVL = (data, itemHandler) => {
  if (!isString(data)) return null;
  const result = querystring.parse(data);
  if (isNullOrEmpty(result)) return null;
  const keys = Object.keys(result);
  if (isFn(itemHandler)) {
    for (let key of keys) {
      const item = result[key];
      result[key] = itemHandler(key, item);
    }
  }
  return result;
};

export const findComponent = (page, componentName, placeholder = 'jss-main') => {
  const placeholders = Object.keys(page.placeholders);
  if (isNullOrEmpty(placeholder)) {
    for (let ph of placeholders) {
      if (!page.placeholders[ph]?.length) continue;
      const cm = page.placeholders[ph].find((x) => equalString(x.componentName, componentName));
      if (cm) {
        return cm;
      }
    }
    return null;
  }
  if (!page.placeholders[placeholder]) return null;
  return page.placeholders[placeholder].find((x) => equalString(x.componentName, componentName));
};
const findElements = (parent, predicate) => {
  if (isNullOrEmpty(parent.fields)) return [];
  // console.log('parent.fields', parent.fields);
  if (predicate(parent)) {
    return [parent];
  }
  const result = [];
  const fKeys = Object.keys(parent.fields);
  for (let fk of fKeys) {
    const field = parent.fields[fk];
    if (isNullOrEmpty(field)) continue;
    if (isArray(field)) {
      for (let child of field) {
        result.push(...findElements(child, predicate));
      }
    }
    if (!isNullOrEmpty(field.fields)) result.push(...findElements(field, predicate));
  }
  return result;
};
export const preloadAdaptiveMedias = (page, metas, placeholder = 'jss-main') => {
  return page.placeholders[placeholder].map((x) => {
    const elements = findElements(x, (item) => {
      return item.fields.preload?.value;
    });
    for (let el of elements) {
      const { desktopVideo, desktopImage, tabletVideo, tabletImage, mobileVideo, mobileImage } = el.fields;
      if (!isNullOrEmpty(mobileVideo?.value)) {
        metas.link.push({
          rel: 'preload',
          as: 'video',
          href: mobileVideo?.value,
          media: 'max-width: 992px',
          crossorigin: true
        });
      }
      if (!isNullOrEmpty(mobileImage?.value?.src)) {
        metas.link.push({
          rel: 'preload',
          as: 'image',
          href: mobileImage?.value?.src,
          media: 'max-width: 992px',
          crossorigin: true
        });
      }
      if (!isNullOrEmpty(tabletVideo?.value)) {
        metas.link.push({
          rel: 'preload',
          as: 'video',
          href: tabletVideo?.value,
          media: '993px<=width<=1365px',
          crossorigin: true
        });
      }
      if (!isNullOrEmpty(tabletImage?.value?.src)) {
        metas.link.push({
          rel: 'preload',
          as: 'image',
          href: tabletImage?.value?.src,
          media: '993px<=width<=1365px',
          crossorigin: true
        });
      }
      if (!isNullOrEmpty(desktopVideo?.value)) {
        metas.link.push({
          rel: 'preload',
          as: 'video',
          href: desktopVideo?.value,
          media: 'min-width: 1366px',
          crossorigin: true
        });
      }
      if (!isNullOrEmpty(desktopImage?.value?.src)) {
        metas.link.push({
          rel: 'preload',
          as: 'image',
          href: desktopImage?.value?.src,
          media: 'min-width: 1366px',
          crossorigin: true
        });
      }
    }
  });
};

import { merge } from 'lodash';
import { isArray, isNullOrEmpty } from '@/utils/obj-utils';
import { APP_CONST } from '@/utils/constants';
import { fm2, formatMoney, toThousands } from '@/utils/math-utils';
import { equalString } from '@/utils/string-utils';
import { findCwBlockDetail } from '@/utils/site-utils';
import utils from '@/utils/index';

export const buildScfConfig = ({ model, assetImage, retailPrice, downPayment, vatAmount, customerType, preSelection, vehicleType = 'New', registrationDate = null }) => {
  const defaultTerms = 60;
  const annualMileage = 15000;
  let deposit = 0; // Math.floor(retailPrice * 0.15) ?? downPayment;
  const clientType = customerType?.code === 'business' ? 'Business' : 'Private';
  const options = {
    clientType: clientType,
    typeVehicle: vehicleType,
    model: model,
    assetPrice: retailPrice,
    assetImage: assetImage,
    term: defaultTerms,
    dealerDeposit: deposit,
    vat: vatAmount ?? 0,
    forceSelection: false
  };
  // if (!isNullOrEmpty(registrationDate)) {
  //   merge(options, {
  //     registrationDate
  //   });
  // }
  if (!isNullOrEmpty(preSelection)) {
    merge(options, {
      forceSelection: {
        term: preSelection.term,
        deposit: preSelection.deposit,
        annualMileage: preSelection.mileage,
        finalPayment: preSelection.finalPayment ?? 0
      }
    });
    if (!isNullOrEmpty(preSelection.loanType)) {
      merge(options, {
        forceSelection: {
          loanType: preSelection.loanType
        }
      });
    }
  }
  if (!isNullOrEmpty(clientType)) {
    merge(options, {
      forceSelection: {
        clientType
      }
    });
  }
  return options;
};
export const buildCwFinanceConfig = ({ features, carSeries, carModel, customerType, cwConfig, basePrice, orderRule }) => {
  const featureOptions = [];
  features.forEach((x) =>
    x.infos.forEach((y) => {
      if (!isNullOrEmpty(y.buttonFeatureType)) {
        featureOptions.push({
          code: y.buttonFeatureType,
          name: y.label,
          price: y.price?.price ?? 0
        });
      }
    })
  );
  if (!isNullOrEmpty(carModel?.packageCode)) {
    featureOptions.push({
      code: carModel?.packageCode,
      name: carModel?.packageCode,
      price: 0
    });
  }
  featureOptions.push({
    code: APP_CONST.market.mhpCode,
    name: APP_CONST.market.mhpCode,
    price: 0
  });
  let productKeys = carSeries.level2PaymentOptions.map((x) => x.fields.abbr);
  // const businessType = customerType.code === 'personal' ? 'PrivateIndividual' : null;
  return {
    element: false,
    entity: '',
    environment: cwConfig?.env ?? 'development',
    alignment: 'center',
    basePrice: basePrice,
    prepayment: orderRule?.orderAmountNumber ?? 0,
    options: featureOptions,
    productKeys,
    modelID: Number(carSeries?.code)
  };
};
export const getCabItem = (calculation, key) => {
  const userSelection = calculation?.userSelection;
  const detailsTable = userSelection?.offer?.instalment?.detailsTable ?? calculation?.instalment?.detailsTable;
  return detailsTable?.find((x) => equalString(x.key, key));
};
export const getCabItemPureVal = (calculation, key) => {
  return getCabItem(calculation, key)?.value;
};
export const getCabItemVal = (calculation, key, payload) => {
  return formatCabValue(getCabItem(calculation, key), payload);
};
export const getCabItemValFromFields = (calculation, keys, payload) => {
  let result = null;
  for (let key of keys) {
    result = getCabItemVal(calculation, key, payload);
    if (!isNullOrEmpty(result)) break;
  }
  return result;
};
export const formatCabValue = (item, payload) => {
  if (isNullOrEmpty(item)) return null;
  const { key, value, type } = item;
  if (key === 'duration') return `${value} months`;
  if (type === 'currency' && !isNullOrEmpty(payload?.currency)) return fm2(value, payload?.currency);
  if (['mileage', 'totalMileage'].includes(key)) return `${toThousands(value)} km`;
  if (type === 'percentage') return `${value}%`;
  return item.value;
};
export const mapCalculationToFormBody = (dockCode, calculation, formBody, payload) => {
  switch (dockCode) {
    case 'code-waver':
      {
        if (isNullOrEmpty(calculation?.codeWaver)) break;
        const cwQuote = calculation.codeWaver.result.quote;
        const optionalFinalPayment = findCwBlockDetail(cwQuote.blocks, 'OptionalFinalPayment');
        merge(formBody, {
          financeProduct: cwQuote.finance.product.name,
          downPayment: fm2(cwQuote.finance.quote.deposit.cash, payload.currency),
          financedAmount: fm2(cwQuote.finance.quote.balance, payload.currency),
          monthlyInstallment: fm2(cwQuote.finance.quote.regularPayment, payload.currency),
          durationMonths: cwQuote.terms ?? cwQuote.finance.quote.contractLength,
          effectiveInterestRate: cwQuote.finance.quote.apr,
          nominalInterestRate: cwQuote.finance.quote.rateOfInterest,
          mileage: cwQuote.finance.quote.annualMileage
        });
        if (!isNaN(optionalFinalPayment?.value)) {
          merge(formBody, {
            finalPurchaseOption: optionalFinalPayment?.displayValue
          });
        }
      }
      break;
    case 'santander':
      {
        if (isNullOrEmpty(calculation?.santander?.selectedSimulation)) break;
        const financeResult = calculation.santander?.selectedSimulation;
        const nominalInterestRate = financeResult?.nominalInterest?.value ?? financeResult?.JKP?.value;
        merge(formBody, {
          financeProduct: calculation.santander.mappedProduct?.name
        });
        utils.obj.appendMoneyIfNotNull(formBody, 'downPayment', financeResult?.downPayment?.value, payload.currency);
        utils.obj.appendMoneyIfNotNull(formBody, 'monthlyInstallment', financeResult?.monthlyPayment?.value, payload.currency);
        utils.obj.appendValueIfNotNull(formBody, 'durationMonths', financeResult?.duration?.value);
        utils.obj.appendValWithTpl(formBody, 'mileage', financeResult?.annualMileage?.value, '##val##Km');
        utils.obj.appendValueIfNotNull(formBody, 'effectiveInterestRate', financeResult?.effectiveInterest?.value);
        utils.obj.appendValueIfNotNull(formBody, 'nominalInterestRate', nominalInterestRate);
        utils.obj.appendMoneyIfNotNull(formBody, 'finalPayment', financeResult?.lastPayment?.value, payload.currency);
        utils.obj.appendMoneyIfNotNull(formBody, 'financedAmount', financeResult?.amountOfCredit?.value, payload.currency);
        utils.obj.appendMoneyIfNotNull(formBody, 'totalCostOfCredit', financeResult?.totalAmountFinanced?.value, payload.currency);
      }
      break;
    case 'ca-auto-bank':
      {
        const detailsTable = calculation?.cab?.instalment?.detailsTable;
        if (!isArray(detailsTable) || detailsTable.length === 0) break;
        formBody.financeProduct = calculation?.cab?.mappedProduct?.name;
        utils.obj.appendValueIfNotNull(formBody, 'downPayment', getCabItemVal(calculation.cab, 'downPayment', payload));
        utils.obj.appendValueIfNotNull(formBody, 'durationMonths', getCabItemPureVal(calculation.cab, 'duration'));
        utils.obj.appendValueIfNotNull(formBody, 'monthlyInstallment', getCabItemVal(calculation.cab, 'monthlyInstalmentAmount', payload));
        utils.obj.appendValueIfNotNull(formBody, 'financedAmount', getCabItemVal(calculation.cab, 'totalCreditAmount', payload));
        utils.obj.appendValueIfNotNull(
          formBody,
          'totalCostOfCredit',
          getCabItemValFromFields(calculation.cab, ['totalCostOfAcquisitionWithServices', 'totalAmountPayableWithServices', 'creditTotalCost'], payload)
        );
        utils.obj.appendValueIfNotNull(formBody, 'mileage', getCabItemVal(calculation.cab, 'mileage'));
        utils.obj.appendValueIfNotNull(formBody, 'effectiveInterestRate', getCabItemPureVal(calculation.cab, 'taeg'));
        utils.obj.appendValueIfNotNull(formBody, 'nominalInterestRate', getCabItemPureVal(calculation.cab, 'interestRate'));
        utils.obj.appendValueIfNotNull(formBody, 'finalPayment', getCabItemVal(calculation.cab, 'lastInstalmentAmount', payload));
        utils.obj.appendValueIfNotNull(formBody, 'finalPurchaseOption', getCabItemValFromFields(calculation.cab, ['baloonPaymentValue', 'residualValue'], payload));
      }
      break;
    default:
      break;
  }
};
